import styled from "styled-components";

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (min-width: 576px) {
    width: 480px;
    max-width: 480px;
    height: auto;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  align-content: stretch;
  ${"" /* height: 100%; */}
  min-height: 100vh;
  @media screen and (min-width: 576px) {
    min-height: auto;
  }
`;

export const ModalHeader = styled.div`
  min-width: 320px;
  background: #19212b;
  text-align: center;
  padding: 86px 16px 62px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  width: 225px;
  height: 177px;
`;

export const ModalTabs = styled.div`
  display: flex;
  flex-direction: row;
  background: #19212b;
`;
export const ModalTab = styled.div`
  width: 50%;
  text-align: center;
  color: #e9f2f8;
  cursor: pointer;

  border-bottom: ${props =>
    !!props.active && props.active ? "3px solid #d6b678" : "3px solid #19212b"};

  a {
    width: 100%;
    height: 100%;
    padding: 16px 8px;
    text-decoration: none;
    color: #e9f2f8;
    display: block;
  }
`;

export const ModalContent = styled.div`
  background: #26303c;
  padding: 16px;
  flex: 1;
`;

export const ModalInputWrapper = styled.div`
  background: #26303c;
  color: #e9f2f8;
  width: 100%;
  height: 48px;
  font-size: 15px;
  border-bottom: 2px solid rgba(216, 216, 216, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  transition: 0.2s;
  position: relative;
  overflow: hidden;

  &:focus-within {
    border-bottom: 2px solid rgba(216, 216, 216, 0.4);
  }

  ${"" /* &:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 44px;
    display: block;
    background-color: #26303c;
    left: 47px;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 44px;
    display: block;
    background-color: #26303c;
    right: -1px;
    z-index: 1;
  } */}
`;

export const ModalInputIcon = styled.img`
  width: 32px;
  height: 32px;
  user-select: none;
  margin-right: 16px;
`;

export const ModalInput = styled.input`
  background: transparent;
  color: #e9f2f8;
  outline: 0;
  width: 100%;
  height: 100%;
  border: 0;
  font-size: 14px;
  padding-left: 4px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: transparent;
    box-shadow: 0 0 0px 1000px #26303c inset, 0 0 0px 1px #26303c;
    -webkit-text-fill-color: #e9f2f8;
    border: 0;
    transition: background-color 5000s ease-in-out 0s;
    height: 90%;
  }
`;

export const Button = styled.button`
  width: 100%;
  background: ${props => (props.active ? "#d6b678" : "#808080")};
  color: #26303c;
  border: 0;
  outline: none;
  padding: 22px;
  font-size: 18px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  text-align: center;
  transition: 0.5s;
`;

export const DisclaimerP = styled.p`
  color: #808080;
  font-size: 12px;
  padding: 0.4em 0;
  font-weight: 200;
  line-height: 1.4em;

  a {
    color: #808080;
  }
`;

export const ErrorsList = styled.ul`
  font-size: 13px;
  color: #f44336;
  background-color: #2f1d1d;
  font-weight: 200;
  padding: 1em 1em 1em 2.2em;
  border-radius: 8px;
  border: 1px solid #612f31;
`;

export const ForgotLink = styled.div`
  text-align: right;
  a {
    color: #808080;
    font-weight: 200;
    font-size: 12px;
  }
`;
