import { userConstants } from "../constants";

export function check_password(state = {}, action) {
  switch (action.type) {
    case userConstants.CHECK_PASSWORD_REQUEST:
      return {
        loading: true
      };
    case userConstants.CHECK_PASSWORD_SUCCESS:
      return {
        data: action.password
      };
    case userConstants.CHECK_PASSWORD_FAILURE:
      return {
        error: action.errors
      };
    case userConstants.CLEAN_STATE:
      return {};
    default:
      return state;
  }
}
