import React, { PureComponent as Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { withTranslation } from "react-i18next";
import { languages } from "../../languages/languages";
import Article from "./Article";
import { NewsBlock, Grid } from "./styled";

import articlesData from "./articlesData";

class News extends Component {
  getCurrentLang = () => {
    const i18nextLng = localStorage.getItem("i18nextLng");
    return languages.find(lang => i18nextLng === lang.code);
  };

  render() {
    const currentLang = this.getCurrentLang();
    let currentLangCode = currentLang.code;
    if (!["ru", "en", "es", "cn"].includes(currentLangCode)) {
      currentLangCode = "en";
    }
    return (
      <NewsBlock>
        <Grid>
          <Row>
            <Col xs={12}>
              <h1>{articlesData.headline[currentLangCode].text}</h1>
              {currentLangCode &&
                articlesData[currentLangCode].length > 0 &&
                articlesData[currentLangCode].map((article, i) => (
                  <Article
                    key={i}
                    date={article.date}
                    title={article.title}
                    text={article.text}
                    active={i === 0}
                  />
                ))}
            </Col>
          </Row>
        </Grid>
      </NewsBlock>
    );
  }
}

export default withTranslation()(News);
