export default {
  translation: {
    "header.account": "Account",
    "header.faq": "FAQ",
    "header.about": "About",
    "header.lectures": "Lectures",
    "header.news": "Project news",
    "header.forum": "Forum",
    "header.manifest": "Declaration",

    founding_date: "State founding date",
    "description": "Vitaspace is the world's first decentralized extraterritorial state, where participants are united by a common idea of the extension of youth and active life without diseases.",
    "join": "Join",

    "footer.rights": "All rights reserved",
    "footer.policy": "Personal Data Processing Policy",
    "footer.agreement": "User agreement",

    "auth.sign.in": "Sign in",
    "auth.sign.up": "Sign up",
    "auth.sign.out": "Sign out",
    "auth.email": "Email",
    "auth.name": "Name",
    "auth.surname": "Surname",
    "auth.phone": "Phone",
    "auth.password": "Password",
    "auth.forgot": "Forgot password",
    "auth.forgot.disclaimer": "Enter your email address to get a new password.",
    "auth.forgot.recover": "Recover the password",
    "auth.recovery": "Password recovery",

    "settings.photo": "Photo",
    "settings.security": "Security",
    "settings.share": "Share my photo on the map",
    "settings.your_photo": "Your photo",
    "settings.upload_photo": "Upload photo",
    "settings.update_photo": "Update photo",
    "settings.visibility": "Photo visibility",

    "registration.policy": `By confirming the registration, you agree to processing of your personal data and user agreement.`,
    "registration.policy_full": `By confirming the registration, you agree to <2>processing of your personal data</2> and <6>user agreement</6>.`,

    upload: "Upload",
    loading: "Loading",
    error: "Error",

    orderVitapass: "Order a tangible VitaPass",
    readMore: "Read more",

    "account.welcome": "Welcome",
    "account.changeCaption":
      "To edit your VitaPass profile (upload a new photo, change your name and surname), please send an e-mail",
    "account.close": "Close",

    "home.description.1": `Vitaspace is the world's first decentralized extraterritorial state, where participants are united by a common idea of the extension of youth and active life without diseases.`,
    "home.description.2": `Many people consider themselves citizens of the world. We also suggest not to be limited to geographical, national and other categories that divide people.`,
    "home.description.3": `Vitaspace will provide an opportunity to rewrite the rules governing the community. Together, the citizens of a decentralized state will join forces to radically extend life.`,
    "home.description.4": `Vitaspace does not have classical controls, and all decisions will be made with the help of blockchain technologies. Every citizen of a decentralized state will receive a digital passport. Its data will be recorded in the blockchain registry.`, // Vitacoin is a digital token of a decentralized state, a measure of exchange and a means of payment.
    "home.description.5": `Each member of Vitaspace will be able to contribute to the development of technologies that allow to preserve youth, restore health and significantly postpone aging. Vitaspace citizens are expected to have the highest life expectancy in the world.`,

    "home.manifest.headline": `Declaration on Problems of Radical Life Extension`,
    "home.manifest.text.1": `According to international legal standards, the right to life is a fundamental human value. We, the citizens and founders 
    of the decentralized extraterritorial State of <a href="/">Vitaspace</a>, and also <a href="https://vitacoin.net/en/" target="_blank">Vitacoin</a> project participants, 
    believe that ageing and death violate this right evidently, and we call on all people to unite around the idea of a radical life extension.`,
    "home.manifest.text.2": `<span>Taking into account</span> that populations around the world are ageing and dying in unprecedented ways,`,
    "home.manifest.text.3": `<span>likewise</span>, considering that all the people and states must join together
    to fight against ageing and death, and declare this issue as a priority,`, 
    "home.manifest.text.4": `<span>admitting</span>, that ageing is a disease jeopardizing human life,`,
    "home.manifest.text.5": `<span>awaring</span> that fundamental changes are needed in the 
    policies of all States with regard to the prevention and treatment of ageing and age-related diseases,`,
    "home.manifest.text.6": `<span>either awaring</span> the fact that significant changes in human consciousness 
    are necessary with regard to ageing and death as inevitable and obligatory phenomena in human life,`,
    "home.manifest.text.7": `<span>Vitaspace citizens</span> and <span>Vitacoin project members proclaim</span> this 
    <span>Declaration on Radical Life Extension</span> as a goal to be pursued by all individuals and governments of all countries.`,

    "description.tab.coatOfArms": "Coat of arms",
    "description.tab.flag": "Flag",

    "description.tab.coatOfArms.content": `The Vitaspace coat of arms is represented by the letter "V", which refers to the 
    Latin word vita, which means "life", and the sign of infinity. Together they form a symbol of eternal life.`,

    "description.tab.flag.content": `It is a rectangular cloth of white color-a symbol of nobility,
    openness and knowledge. In the center of the flag there is the
    coat of arms of the decentralized extraterritorial state
    Vitaspace.`,

    "description.tab.vitapass.content.1": `Vitapass is the main electronic document of the established form
    that certifies the identity of a Vitaspace citizen.To obtain
    Vitapass you have to go through a simple registration. The
    following information is entered in the passport:`,
    "description.tab.vitapass.content.2": `Electronic passport can be purchased in one of two analog types:
    gold or platinum, which are a plastic card of the established
    sample.`,
    "description.tab.vitapass.content.li.names":
      "surname, first name, patronymic of the citizen",
    "description.tab.vitapass.content.li.date": "date of birth",
    "description.tab.vitapass.content.li.id": "identification number.",

    "google.recaptcha.terms":
      "This site is protected by reCAPTCHA and the Google",
    "google.recaptcha.terms.and": "and",
    "google.recaptcha.terms.policy": "Privacy Policy",
    "google.recaptcha.terms.terms": "Terms of Service",
    "google.recaptcha.terms.google": "apply.",

    "settings.back.button": "Back",
    "settings.settings": "Settings",
    "settings.change_password": "Change Password",
    "setting.password_doest_match": "Passwords do not match",
    "settings.old_password": "Old password",
    "settings.new_password": "New password",
    "settings.re_new_password": "Re:new password",
    "settings.save.button": "Save",

    "graph.usercount": "Amount of users"
  }
};
