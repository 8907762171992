/* eslint-disable react/jsx-no-target-blank */
import React, { PureComponent as Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { userActions } from "../../actions";
import { ReCaptcha } from "react-recaptcha-v3";
import ym from "react-yandex-metrika";

import {
  ModalWrapper,
  ModalHeader,
  Logo,
  ModalTabs,
  ModalTab,
  ModalContent,
  ModalInputWrapper,
  ModalInputIcon,
  ModalInput,
  Button,
  DisclaimerP,
  ErrorsList,
  ForgotLink,
  StyledForm
} from "./styled";

import { Spinner } from "../Spinner";

import logo from "./gerb_web_mh.svg";
import email_icon from "./email.svg";
import password_icon from "./password.svg";

class LoginForm extends Component {
  state = {
    email: "",
    password: "",
    submitted: false,
    recaptchaToken: ""
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());
  }

  componentDidMount() {
    this._isMounted = true;
    ym("hit", "/login");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  verifyCallback = recaptchaToken => {
    if (this._isMounted) {
      this.setState({ recaptchaToken: recaptchaToken });
    }
  };

  handleChange = e => {
    let { name, value } = e.target;
    if (name === "email") {
      value = value.toLowerCase();
    }
    if (this._isMounted) {
      this.setState({ [name]: value });
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    const { email, password, recaptchaToken } = this.state;

    const { dispatch } = this.props;
    if (email && password && recaptchaToken && this._isMounted) {
      dispatch(userActions.login(email, password, recaptchaToken));
      this.setState(prevState => ({
        submitted: true,
        recaptchaToken: ""
      }));
      this.recaptcha.execute();
    }
  };

  render() {
    // const { loggingIn } = this.props;
    const { t, errors, loggingIn } = this.props;
    const { email, password } = this.state;

    return (
      <ModalWrapper>
        <StyledForm onSubmit={this.handleSubmit}>
          <ModalHeader>
            <Logo src={logo} alt="VitaSpace" />
          </ModalHeader>
          <ModalTabs>
            <ModalTab active={true}>
              <Link to="/login">{t("auth.sign.in")}</Link>
            </ModalTab>
            <ModalTab>
              <Link to="/register">{t("auth.sign.up")}</Link>
            </ModalTab>
          </ModalTabs>
          <ModalContent>
            {errors && Array.isArray(errors) && errors.length > 0 && (
              <ErrorsList>
                {errors.map((error, idx) => (
                  <li key={idx}>{error}</li>
                ))}
              </ErrorsList>
            )}
            <ModalInputWrapper>
              <ModalInputIcon src={email_icon} alt="" />
              <ModalInput
                type="text"
                placeholder={t("auth.email")}
                id="email"
                name="email"
                value={email}
                onChange={this.handleChange}
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputIcon src={password_icon} alt="" />
              <ModalInput
                type="password"
                placeholder={t("auth.password")}
                id="password"
                name="password"
                value={password}
                onChange={this.handleChange}
              />
            </ModalInputWrapper>
            <ForgotLink>
              <Link to="/forgot">{t("auth.forgot")}?</Link>
            </ForgotLink>
            <ReCaptcha
              ref={ref => (this.recaptcha = ref)}
              sitekey={process.env.REACT_APP_GR_CAPTCHA_SITE_KEY}
              action="login"
              verifyCallback={this.verifyCallback}
            />
            {this.state.recaptchaToken.length > 0 ? (
              <DisclaimerP>
                {t("google.recaptcha.terms")}{" "}
                <a target="_blank" href="https://policies.google.com/privacy">
                  {t("google.recaptcha.terms.policy")}
                </a>{" "}
                {t("google.recaptcha.terms.and")}{" "}
                <a target="_blank" href="https://policies.google.com/terms">
                  {t("google.recaptcha.terms.terms")}
                </a>{" "}
                {t("google.recaptcha.terms.google")}
              </DisclaimerP>
            ) : (
              <DisclaimerP>
                <Spinner /> Loading reCaptcha ...
              </DisclaimerP>
            )}
          </ModalContent>
          <Button
            disabled={this.state.recaptchaToken.length === 0}
            active={this.state.recaptchaToken.length > 0}
            to="/account"
          >
            {loggingIn ? <Spinner /> : t("auth.sign.in")}
          </Button>
        </StyledForm>
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, errors } = state.authentication;
  return {
    loggingIn,
    errors
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginForm);

export default withTranslation()(connectedLoginPage);
