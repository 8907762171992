import styled from "styled-components";
import { Link } from "react-router-dom";
import background from "./687.jpg"

export const HomeStartSection = styled.section`
  background-color: #26303c;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  position: relative; 
`;

export const LogoBlock = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;

  padding-left: 27vw;
  padding-top: 30vh;

  @media screen and (max-width: 510px){
    justify-content: center;
    align-items: center;
    padding-left: 28px;
    padding-right: 28px;
  }
`;

export const LogoCaption = styled.p`
  color: #9C9689;;
  font-weight: 100;
  font-size: 14px;
  opacity: 0.8;
  max-width: 496px;
  max-height: 64px;

  @media screen and (max-width: 510px){
    max-width: 320px;
    max-height: 68px;
  }

`;

export const LogoImg = styled.img`
  width: 100%;
  height: 100%;
  max-width: 616px;
  max-height: 168px;
  user-select: none;
  margin-bottom: 24px;

  @media screen and (max-width: 1600px){
    max-width: 440px;
    max-height: 120px;
  }

  @media screen and (max-width: 510px){
    max-width: 320px;
    max-height: 88px;
  }
`;

export const ButtonP = styled.p`
  text-align: center;
  width: 168px;
  height: 40px;

  padding-top: 8px;

  border: 1px solid #D2B47A;
  box-sizing: border-box;
  border-radius: 30px;
  background: none;

  @media screen and (max-width: 510px){
    width: 320px;
    height: 56px;
    margin-top: 128px;
    padding-top: 16px;
  }

  :hover{
    background: #D2B47A;
    color: #8C6419;
  }
`;

export const LogoButton = styled(Link)`
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;

  color: #D2B47A;

  margin-top: 24px;

  outline: none;
`;