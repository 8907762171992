/* eslint-disable react/jsx-no-target-blank */
import React, { PureComponent as Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { languages } from "../../languages/languages";
import { userActions } from "../../actions";
import { ReCaptcha } from "react-recaptcha-v3";
import AvatarImageCropper from "react-avatar-image-cropper";
import ym from "react-yandex-metrika";

import {
  ModalWrapper,
  ModalHeader,
  DefaultAvatar,
  DefaultAvatarPlus,
  DefaultAvatarRemove,
  ModalTabs,
  ModalTab,
  ModalContent,
  ModalInputWrapper,
  ModalInputIcon,
  ModalInput,
  Button,
  StyledButtonApply,
  StyledButtonCancel,
  DisclaimerP,
  ErrorsList,
  StyledForm
} from "./styled";

import { Spinner } from "../Spinner";

import email_icon from "./email.svg";
import phone_icon from "./telephone.svg";
import user_icon from "./user.svg";

const initialState = {
  src: "",
  user: {
    name: "",
    lastname: "",
    phone: "",
    email: "",
    image: ""
  },
  image_file: false,
  image_src: "",
  submitted: false,
  recaptchaToken: ""
};

class RegisterForm extends Component {
  state = initialState;

  _isMounted = false;

  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());
  }

  componentDidMount() {
    this._isMounted = true;
    ym("hit", "/register");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getCurrentLang = () => {
    const i18nextLng = localStorage.getItem("i18nextLng");
    return languages.find(lang => i18nextLng === lang.code);
  };

  verifyCallback = recaptchaToken => {
    if (this._isMounted) {
      this.setState({ recaptchaToken: recaptchaToken });
    }
  };

  handleApply = file => {
    // handle the blob file you want
    // such as get the image src
    const src = window.URL.createObjectURL(file);
    if (this._isMounted) {
      this.setState(prevState => ({
        user: {
          ...prevState.user
        },
        image_file: file,
        image_src: src
      }));
    }
  };

  handleCancel = () => {
    if (this._isMounted) {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          image: ""
        },
        image_file: false,
        image_src: ""
      }));
    }
  };

  handleError = () => {};

  handleChange = event => {
    let { name, value } = event.target;

    if (name === "email") {
      value = value.toLowerCase();
    }

    if (this._isMounted) {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          [name]: value
        }
      }));
    }
  };

  handleRegister = event => {
    event.preventDefault();
    // const data = new FormData(event.target);

    // const dataForm = {
    //   name: data.get("name"),
    //   lastname: data.get("lastname"),
    //   phone: data.get("phone"),
    //   email: data.get("email"),
    //   image: this.state.src.length > 0 ? this.state.src : false
    // };

    const { user, recaptchaToken } = this.state;

    const { dispatch } = this.props;

    const form_data = new FormData();

    if (
      user.name &&
      user.lastname &&
      user.email &&
      user.phone &&
      this._isMounted
    ) {
      const user_data = {
        email: user.email,
        phone_number: user.phone,
        first_name: user.name,
        last_name: user.lastname
      };
      for (var key in user_data) {
        form_data.append(key, user_data[key]);
      }

      form_data.append("recaptchaToken", recaptchaToken);

      if (this.state.image_src.length > 0 && this.state.image_file) {
        form_data.append("avatar", this.state.image_file, "avatar.png");
      }

      dispatch(userActions.register(form_data));
      // this.setState(initialState);
      this.setState({ submitted: true, recaptchaToken: "" });
      this.recaptcha.execute();
    }
  };

  render() {
    // const { registering } = this.props;
    // const { user, submitted } = this.state;
    const { t, errors, registering } = this.props;
    const { user } = this.state;
    const current_lang = this.getCurrentLang();

    return (
      <ModalWrapper>
        <ModalHeader>
          <DefaultAvatar background={this.state.image_src}>
            {this.state.image_src.length === 0 && (
              <DefaultAvatarPlus>+</DefaultAvatarPlus>
            )}
            {this.state.image_src.length > 0 && (
              <DefaultAvatarRemove onClick={this.handleCancel}>
                +
              </DefaultAvatarRemove>
            )}
            {this.state.image_src.length === 0 && (
              <AvatarImageCropper
                apply={this.handleApply}
                cancel={this.handleCancel}
                text={t("upload")}
                errorHandler={this.handleError}
                loadingNode={
                  <div style={{ color: "#e9f2f8" }}>{t("loading")}</div>
                }
                textStyle={{ display: "none" }}
                iconStyle={{ display: "none" }}
                sliderConStyle={{ background: "none" }}
                rootStyle={{ borderRadius: "30%" }}
                sliderStyle={{ background: "#d6b678", height: "3px" }}
                sliderBtnStyle={{
                  background: "#d6b678",
                  top: "-6px",
                  borderColor: "#19212b"
                }}
                actions={[
                  <StyledButtonApply key={1}>
                    <svg
                      fill="#949494"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                  </StyledButtonApply>,
                  <StyledButtonCancel key={0}>
                    <svg
                      fill="#26303c"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                    </svg>
                  </StyledButtonCancel>
                ]}
              />
            )}
          </DefaultAvatar>
        </ModalHeader>
        <ModalTabs>
          <ModalTab>
            <Link to="/login">{t("auth.sign.in")}</Link>
          </ModalTab>
          <ModalTab active={true}>
            <Link to="/register">{t("auth.sign.up")}</Link>
          </ModalTab>
        </ModalTabs>
        <StyledForm onSubmit={this.handleRegister}>
          <ModalContent>
            {errors && Array.isArray(errors) && errors.length > 0 && (
              <ErrorsList>
                {errors.map((error, idx) => (
                  <li key={idx}>{error}</li>
                ))}
              </ErrorsList>
            )}
            {errors && typeof errors === "object" && errors !== null && (
              <ErrorsList>
                {Object.keys(errors).map((error, idx) => (
                  <ul key={idx}>
                    {errors[error].map((suberror, subidx) => (
                      <li key={subidx}>{suberror}</li>
                    ))}
                  </ul>
                ))}
              </ErrorsList>
            )}
            <ModalInputWrapper>
              <ModalInputIcon src={user_icon} alt="" />
              <ModalInput
                id="name"
                name="name"
                value={user.name}
                onChange={this.handleChange}
                type="text"
                placeholder={t("auth.name")}
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputIcon src={user_icon} alt="" />
              <ModalInput
                id="lastname"
                name="lastname"
                value={user.lastname}
                onChange={this.handleChange}
                type="text"
                placeholder={t("auth.surname")}
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputIcon src={phone_icon} alt="" />
              <ModalInput
                id="phone"
                name="phone"
                value={user.phone}
                onChange={this.handleChange}
                type="text"
                placeholder={t("auth.phone")}
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputIcon src={email_icon} alt="" />
              <ModalInput
                id="email"
                name="email"
                value={user.email}
                onChange={this.handleChange}
                type="text"
                placeholder={t("auth.email")}
              />
            </ModalInputWrapper>
            <ReCaptcha
              ref={ref => (this.recaptcha = ref)}
              sitekey={process.env.REACT_APP_GR_CAPTCHA_SITE_KEY}
              action="login"
              verifyCallback={this.verifyCallback}
            />
            <DisclaimerP>
              <Trans i18nKey="registration.policy_full">
                By confirming the registration, you agree to{" "}
                <a
                  href={`https://vitaspace.net${current_lang &&
                    current_lang.code &&
                    `/${current_lang.code}`}/privacy-policy.html`}
                  target="_blank"
                >
                  processing of your personal data
                </a>{" "}
                and{" "}
                <a
                  href={`https://vitaspace.net${current_lang &&
                    current_lang.code &&
                    `/${current_lang.code}`}/terms-of-use.html`}
                  target="_blank"
                >
                  user agreement
                </a>
                .
              </Trans>
              {/* {t("registration.policy")} */}
              <br />
              {this.state.recaptchaToken.length > 0 ? (
                <Fragment>
                  {t("google.recaptcha.terms")}{" "}
                  <a target="_blank" href="https://policies.google.com/privacy">
                    {t("google.recaptcha.terms.policy")}
                  </a>{" "}
                  {t("google.recaptcha.terms.and")}{" "}
                  <a target="_blank" href="https://policies.google.com/terms">
                    {t("google.recaptcha.terms.terms")}
                  </a>{" "}
                  {t("google.recaptcha.terms.google")}
                </Fragment>
              ) : (
                <>
                  <Spinner /> Loading reCaptcha ...
                </>
              )}
            </DisclaimerP>
          </ModalContent>
          <Button
            active={this.state.recaptchaToken.length > 0}
            disabled={this.state.recaptchaToken.length === 0}
          >
            {registering ? <Spinner /> : t("auth.sign.up")}
          </Button>
        </StyledForm>
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { registering, user, errors } = state.registration;
  return {
    registering,
    user,
    errors
  };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterForm);

export default withTranslation()(connectedRegisterPage);
