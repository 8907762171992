import React, { PureComponent as Component } from "react";
// import onClickOutside from "react-onclickoutside";
import ReactHtmlParser from "react-html-parser";
import { QuestionWrapper, QuestionText, QuestionAnswer } from "./styled";

const initialState = { active: false };

class Question extends Component {
  state = initialState;

  handleClick = () =>
    this.setState(prevState => ({
      active: !prevState.active
    }));

  componentWillMount() {
    this.props.active === true &&
      this.setState(prevState => ({
        active: true
      }));
  }

  // handleClickOutside = event =>
  //   this.setState({
  //     active: false
  //   });

  render() {
    const { text, answer } = this.props;

    return (
      <QuestionWrapper>
        <QuestionText onClick={this.handleClick} active={this.state.active}>
          {ReactHtmlParser(text)}
        </QuestionText>
        {this.state.active && (
          <QuestionAnswer>{ReactHtmlParser(answer)}</QuestionAnswer>
        )}
      </QuestionWrapper>
    );
  }
}

// export default onClickOutside(Question);
export default Question;
