import React, { createRef, PureComponent as Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { readFile } from "../../../helpers/readFile";
import getCroppedImg from "../../../helpers/cropImage";

import Cropper from "react-easy-crop";

import { userActions } from "../../../actions";

import {
  ModalWrapper,
  ModalContent,
  Grid,
  SystemMessage,
  Logout
} from "./../styled";
import {
  Options,
  Option,
  OptionText,
  ModalHeader,
  ModalTabs,
  ModalTab,
  UploadPhotoButton,
  RemovePhotoButton,
  UploadPhotoBlock,
  PhotoViewBlock,
  Slider,
  CropContainer,
  CropControls
} from "./styled";

import Switch from "react-switch";

import { ModalInnerContent, PrevPage } from "./styled";

import logout_icon from "../logout_icon.svg";
import Loading from "../../Loading";
import { Spinner } from "../../Spinner";

const initialState = {
  uploading: false,
  uploadingStart: false,
  imageSrc: null,
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 1 / 1,
  croppedAreaPixels: false,
  newPhoto: false
};

class PhotoBlock extends Component {
  state = initialState;

  photoInputRef = createRef();

  componentDidMount() {
    const { user, user_data, dispatch } = this.props;
    if (!user_data.data && user && user.id) {
      dispatch(userActions.getById(user.id));
    }
  }

  componentDidUpdate(prevProps) {
    const { user_data } = this.props;
    if (
      prevProps.user_data.data &&
      user_data.data &&
      prevProps.user_data.data.avatar !== user_data.data.avatar
    ) {
      this.setState(initialState);
    }
  }

  handleUsePhoto = () => {
    const { user_data, dispatch } = this.props;

    if (user_data && user_data.data.profile) {
      dispatch(userActions.showInMap(!user_data.data.profile.show_in_map));
    }
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };

  onZoomChange = zoom => {
    this.setState({ zoom });
  };

  onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl = await readFile(e.target.files[0]);
      this.setState({
        imageSrc: imageDataUrl,
        crop: { x: 0, y: 0 },
        zoom: 1,
        uploading: true
      });
    }
  };

  showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        this.state.croppedAreaPixels
      );
      // this.setState({ ...initialState, newPhoto: croppedImage });
      const { user_data, dispatch } = this.props;
      if (user_data && user_data.data && user_data.data.id && croppedImage) {
        dispatch(userActions.updatePhoto(user_data.data.id, croppedImage));
        this.setState(initialState);
      }
    } catch (e) {
      console.error(e);
    }
  };

  onUploadButtonClick = () => {
    if (!this.state.uploading) {
      this.photoInputRef.current.value = null;
      this.photoInputRef.current.click();
    } else {
      this.setState({ uploadingStart: true });
      this.showCroppedImage();
    }
  };

  render() {
    const { t, user_data } = this.props;

    return (
      <ModalWrapper style={{ maxWidth: "576px" }}>
        <ModalHeader>
          <Grid>
            <PrevPage>
              <Link to="/">{t("settings.back.button")}</Link>
            </PrevPage>
            <h2>{t("settings.settings")}</h2>
          </Grid>
          <ModalTabs>
            <ModalTab active={true}>
              <Link to="/settings/photo">{t("settings.photo")}</Link>
            </ModalTab>
            <ModalTab>
              <Link to="/settings/security">{t("settings.security")}</Link>
            </ModalTab>
          </ModalTabs>
        </ModalHeader>
        <ModalContent>
          <Grid>
            {user_data.loading && (
              <SystemMessage>
                <Loading light big />
              </SystemMessage>
            )}
            {user_data.error && (
              <>
                <SystemMessage>{t("error")}.</SystemMessage>
                <SystemMessage>
                  <Logout to="/login">
                    <span>{t("auth.sign.out")}</span>
                    <img src={logout_icon} alt="" />
                  </Logout>
                </SystemMessage>
              </>
            )}
            {!user_data.loading &&
              !user_data.error &&
              user_data &&
              user_data.data &&
              user_data.data.profile && (
                <>
                  <ModalInnerContent>
                    <h3>{t("settings.your_photo")}</h3>
                    {user_data.data.avatar && !this.state.uploading && (
                      <PhotoViewBlock>
                        <img
                          src={user_data.data.avatar}
                          alt={t("settings.your_photo")}
                        />
                      </PhotoViewBlock>
                    )}
                    <UploadPhotoBlock>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        name="photo"
                        ref={this.photoInputRef}
                        style={{ display: "none" }}
                        onChange={this.onFileChange}
                      />
                      {this.state.imageSrc && (
                        <>
                          <CropContainer>
                            <Cropper
                              cropShape="round"
                              image={this.state.imageSrc}
                              crop={this.state.crop}
                              zoom={this.state.zoom}
                              aspect={this.state.aspect}
                              onCropChange={this.onCropChange}
                              onCropComplete={this.onCropComplete}
                              onZoomChange={this.onZoomChange}
                            />
                          </CropContainer>
                          <CropControls>
                            <Slider
                              type="range"
                              min={1}
                              max={3}
                              value={this.state.zoom}
                              step={0.1}
                              onChange={e =>
                                this.setState({ zoom: e.target.value })
                              }
                            />
                          </CropControls>
                        </>
                      )}
                      <UploadPhotoButton onClick={this.onUploadButtonClick}>
                        {this.state.uploadingStart ? (
                          <Spinner />
                        ) : user_data.data.avatar ? (
                          t("settings.update_photo")
                        ) : (
                          t("settings.upload_photo")
                        )}
                      </UploadPhotoButton>
                      {/* {user_data.data.avatar && (
                        <RemovePhotoButton>Удалить</RemovePhotoButton>
                      )} */}
                      {this.state.uploading && (
                        <RemovePhotoButton
                          onClick={() => this.setState(initialState)}
                        >
                          Сброс
                        </RemovePhotoButton>
                      )}
                    </UploadPhotoBlock>
                  </ModalInnerContent>
                  <ModalInnerContent>
                    <h3>{t("settings.visibility")}</h3>
                    <Options>
                      <Option>
                        <OptionText>{t("settings.share")}:</OptionText>
                        <div>
                          <Switch
                            onChange={this.handleUsePhoto}
                            checked={
                              user_data && user_data.data.profile
                                ? user_data.data.profile.show_in_map
                                : false
                            }
                            offColor="#435263"
                            onColor="#d6b677"
                            // onColor="#4CAF50"
                            // onColor="#d8b061"
                            // offHandleColor="#ffffff"
                            offHandleColor="#19212b"
                            // onHandleColor="#ffffff"
                            onHandleColor="#8a6110"
                            height={32}
                            width={64}
                            handleDiameter={24}
                            activeBoxShadow="0 0 2px 3px rgba(0, 0, 0, 0.16)"
                          />
                        </div>
                      </Option>
                    </Options>
                  </ModalInnerContent>
                </>
              )}
          </Grid>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { registration, authentication, user_data } = state;
  const { user } = registration.user ? registration : authentication;
  return {
    user,
    user_data
  };
}

const connectedPhototBlock = connect(mapStateToProps)(PhotoBlock);
export default withTranslation()(connectedPhototBlock);
