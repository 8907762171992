import React, { Fragment, PureComponent as Component } from "react";
import styled from "styled-components";

import vitapass_logo from "./vitapass_logo.svg";
import vitaspace_logo from "./vitaspace_logo.svg";
import background from "../HomeStartScreen/687.jpg"

import "../../fonts/ocra.css";

const CardWrapper = styled.div`
  width: 272px;
  height: 170px;

  margin: 0 auto;
  background: linear-gradient(8.67deg, #242d3a -14.63%, #101a26 118%);
  background-color: #26303c;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  ${"" /* transition: 0.5s;
  backface-visibility: hidden;
  animation-fill-mode: backwards;
  -animation-direction: normal;
  -webkit-animation-fill-mode: backwards; */}

  @media screen and (min-width: 576px) {
    width: 480px;
    height: 300px;
    padding: 24px 30px;
    ${"" /* transform: scale(0.95); */}
  }

  ${"" /* ${props => (props.active ? `opacity: 1;` : `opacity: 0.8;`)} */}

  &:hover {
    ${"" /* opacity: 1; */}
    ${"" /* transform: scale(1); */}
  }
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const VitapassLogo = styled.img`
  user-select: none;
  max-width: 67px;

  @media screen and (min-width: 576px) {
    max-width: 126px;
  }
`;

const VitaspaceLogo = styled.img`
  user-select: none;
  max-width: 70px;

  @media screen and (min-width: 576px) {
    max-width: 132px;
  }
`;

const Avatar = styled.div`
  margin-top: 32px;
  border: 1px solid #d2b47a;
  border-radius: 10px;
  height: 51px;
  width: 51px;
  background-image: ${props =>
    props.photo ? `url("${props.photo}")` : "none"};
  background-size: cover;
  backgroun-position: center center;

  @media screen and (min-width: 576px) {
    height: 104px;
    width: 96px;
  }
`;

const Number = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #D6B678;
  width: 100%;
  position: relative;

  span {
    font-family: "Futura" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 58px;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (min-width: 576px) {
      font-size: 32px;
    }
  }
  div {
    position: absolute;
    bottom: -1em;
    left: 0;
    font-family: "Futura" !important;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (min-width: 576px) {
      font-size: 18px;
    }
  }

  .valid {
    font-size: 8px;
    margin-bottom: 8px;
  }

  .arrow {
    margin-right: 8px;
  }

  .identifier {
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

const Name = styled.div`
  font-family: "Futura";
  font-style: normal;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  color: #D6B678;
  font-size: 20px;
  line-height: 39px;
  

  /* @media screen and (min-width: 576px) {
    font-size: 26px;
  } */

  span {
    font-family: inherit;
  }
`;

const ValidThruBlock = styled.div`
  font-family: "Futura" !important;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  color: #d2b47a;
  font-size: 26px;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 14px;
  div {
    font-family: inherit;
    text-align: center;
    font-size: 10px;
    display: inline-block;

    &:nth-child(1) {
      font-size: 10px;
    }

    &:nth-child(2) {
      margin-left: 8px;
      margin-right: 8px;
    }

    &:nth-child(3) {
      font-size: 20px;
    }
  }
`;

const LineBottom = styled(Line)`
  align-items: flex-end;
`;

export default class VitaPassCard extends Component {
  render() {
    const {
      name,
      lastname,
      photo,
      number,
      identifier,
      valid
    } = this.props.card;

    let numbers;
    if (!!number && number.length > 0) {
      numbers = number.toString().match(/.{1,4}/g);
    }

    return (
      <CardWrapper active={this.props.active}>
        <Line>
          <VitaspaceLogo src={vitaspace_logo} />
          <Avatar photo={photo} />
        </Line>
        <Line>
          <Number>
            {/* <div className="valid" >
              VALID
              <br />
              THRU
            </div>
            <div className="arrow">
              <svg
                width="10"
                height="10"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 0V13.243H0.00549898L13.445 6.62354L0 0Z"
                  fill="#D6B579"
                />
              </svg>
            </div>
            <div>{!!valid && valid.length > 0 ? valid : "XX/XX"}</div> */}
          </Number>
        </Line>
        <Line>
          <Number>
            {!!numbers && numbers.length > 0 ? (
              <Fragment>
                {numbers.map((num, nidx) => (
                  <span key={nidx}>{num}</span>
                ))}
              </Fragment>
            ) : (
              <Fragment>
                <span>XXXX</span>
                <span>XXXX</span>
                <span>XXXX</span>
                <span>XXXX</span>
              </Fragment>
            )}
            {!!identifier && identifier.length > 0 ? (
              <div className="identifier" >{identifier}</div>
            ) : (
              <div>XXXX</div>
            )}
          </Number>
        </Line>
        <LineBottom>
          <Name>
            {!!name && name.length > 0 ? (
              <span>{name} </span>
            ) : (
              <span>Name </span>
            )}
            {!!lastname && lastname.length > 0 ? (
              <span>{lastname}</span>
            ) : (
              <span>Surname</span>
            )}
          </Name>
          <ValidThruBlock>
            <VitapassLogo src={vitapass_logo} />
          </ValidThruBlock>
        </LineBottom>
      </CardWrapper>
    );
  }
}
