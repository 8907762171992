export default {
  translation: {
    "header.account": "Cuenta personal",
    "header.faq": "Preguntas frecuentes",
    "header.about": "About",

    founding_date: "Fecha de fundación del estado",
    "description": "Vitaspace es el primer estado extraterritorial descentralizado del mundo, dónde los participantes están unidos por una idea común de prolongar la juventud y la vida activa sin enfermedades.",
    "join": "Unirse",

    "footer.rights": "Todos los derechos reservados",
    "footer.policy": "Política de privacidad",
    "footer.agreement": "Términos de servicio",

    "auth.sign.in": "Entrada",
    "auth.sign.up": "Registro",
    "auth.sign.out": "Salida",
    "auth.email": "E-mail",
    "auth.name": "Nombre",
    "auth.surname": "Apellidos",
    "auth.phone": "Teléfono",
    "auth.password": "Contraseña",

    "registration.policy": `Al confirmar el registro, usted acepta el procesamiento de sus datos personales, así como los términos del acuerdo de usuario.`,
    "registration.policy_full": `Al confirmar el registro, usted acepta el <2>procesamiento de sus datos personales</2> así como <6>los términos del acuerdo de usuario</6>.`,

    upload: "Upload",
    loading: "Loading",
    error: "Error",

    orderVitapass: "Ordenar Vitapass real",
    "account.welcome": "Bienvenido",
    "account.changeCaption": `Para realizar cambios en el perfil de VitaPass
    (cargar una nueva foto, cambiar el apellido y el nombre), envíe la solicitud correspondiente por correo electrónico a`,

    "home.description.1": `Vitaspace es el primer estado extraterritorial descentralizado del mundo, dónde los participantes están unidos por una idea común de prolongar la juventud y la vida activa sin enfermedades.`,
    "home.description.2": `Muchas personas se consideran ciudadanos del mundo. También sugerimos no limitarse a categorías geográficas, nacionales u otras que separan a las personas.`,
    "home.description.3": `Vitaspace brindará la oportunidad de reescribir las reglas que rigen la sociedad. Juntos, los ciudadanos de un estado descentralizado, unirán las fuerzas con el objetivo de prolongar sus vidas de una forma radical. `,
    "home.description.4": `Vitaspace no tiene organismos públicos clásicos, y todas las decisiones se tomarán utilizando las tecnologías de blockchain. Cada ciudadano del estado descentralizado recibirá un pasaporte digital. Sus datos se registrarán en el registro de blockchain.`, //Vitacoin es un token digital de un estado descentralizado, una medida de intercambio y un medio de pago.
    "home.description.5": `Cada miembro de Vitaspace podrá contribuir al desarrollo de las tecnologías que permitan preservar la juventud, restaurar la salud y retrasar significativamente el envejecimiento. Se espera que la esperanza de vida de los ciudadanos de Vitaspace será la más alta del mundo.`,

    "description.tab.coatOfArms": "Escudo",
    "description.tab.flag": "Bandera",

    "description.tab.coatOfArms.content": `El escudo de armas de Vitaspace está representado por la letra "V", 
    que se refiere a la palabra latina vita, que significa "vida", y el signo del infinito. Juntos forman un símbolo de la vida eterna.`,

    "description.tab.flag.content": `Es una tela blanca rectangular, símbolo de
    nobleza, franqueza y conocimiento. En el centro de la bandera se encuentra el
    emblema del estado extraterritorial
    descentralizado de Vitaspace.`,

    "description.tab.vitapass.content.1": `Vitapass es el principal documento electrónico de
    la forma establecida que identifica al ciudadano
    de Vitaspace. Para obtener Vitapass se necesita pasar por un
    simple registro. La siguiente información se ingresa en el
    pasaporte:`,
    "description.tab.vitapass.content.2": `El pasaporte electrónico se puede adquirir en uno de dos tipos analógicos: de oro o platino, que representan una tarjeta de
    plástico de la muestra establecida.`,
    "description.tab.vitapass.content.li.names":
      "apellido, nombre, nombre patronímico del ciudadano",
    "description.tab.vitapass.content.li.date": "fecha de nacimiento",
    "description.tab.vitapass.content.li.id": "número de identificación.",

    "google.recaptcha.terms":
      "Este sitio está protegido por reCaptcha, al que se aplican la",
    "google.recaptcha.terms.and": "y los",
    "google.recaptcha.terms.policy": "Política de privacidad",
    "google.recaptcha.terms.terms": "Términos de servicio",
    "google.recaptcha.terms.google": "de Google."
  }
};
