import React from "react";
import { Redirect } from "react-router-dom";
import { ModalRoute } from "react-router-modal";

export default ({ props, component: Component, ...rest }) => {
  return (
    <ModalRoute {...rest}>
      {localStorage.getItem("user") ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />
      )}
    </ModalRoute>
  );
};
