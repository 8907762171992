import { userConstants } from "../constants";
import { userService } from "../services";
import { history } from "../helpers";

export const userActions = {
  login,
  logout,
  register,
  getById,
  checkPassword,
  changePassword,
  forgot,
  updatePhoto,
  showInMap
};

function login(email, password, recaptchaToken) {
  return dispatch => {
    dispatch(request({ email }));

    userService.login(email, password, recaptchaToken).then(
      user => {
        dispatch(success(user));
        history.push("/account");
      },
      errors => {
        dispatch(failure(errors));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(errors) {
    return { type: userConstants.LOGIN_FAILURE, errors };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return dispatch => {
    dispatch(request(user));

    userService.register(user).then(
      user => {
        dispatch(success(user));
        history.push("/account");
      },
      errors => {
        dispatch(failure(errors));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(errors) {
    return { type: userConstants.REGISTER_FAILURE, errors };
  }
}

function forgot(email, recaptchaToken) {
  return dispatch => {
    dispatch(request(email, recaptchaToken));

    userService.forgot(email, recaptchaToken).then(
      forgot => {
        dispatch(success(forgot));
        // history.push("/account");
      },
      errors => {
        dispatch(failure(errors));
      }
    );
  };

  function request(user) {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST, email };
  }
  function success(forgot) {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS, forgot };
  }
  function failure(errors) {
    return { type: userConstants.FORGOT_PASSWORD_FAILURE, errors };
  }
}

function getById(id) {
  return dispatch => {
    dispatch(request());

    userService.getById(id).then(
      user_data => dispatch(success(user_data)),
      error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETBYID_REQUEST };
  }
  function success(user_data) {
    return { type: userConstants.GETBYID_SUCCESS, user_data };
  }
  function failure(error) {
    return { type: userConstants.GETBYID_FAILURE, error };
  }
}

function checkPassword(clean, password) {
  return dispatch => {
    if (clean) {
      dispatch(cleanState());
    } else {
      dispatch(request(password));

      userService.checkPassword(password).then(
        password => {
          dispatch(success({ check_password: true }));
        },
        errors => {
          dispatch(failure(errors));
        }
      );
    }
  };

  function request(password) {
    return { type: userConstants.CHECK_PASSWORD_REQUEST, password };
  }
  function success(password) {
    return { type: userConstants.CHECK_PASSWORD_SUCCESS, password };
  }
  function failure(errors) {
    return { type: userConstants.CHECK_PASSWORD_FAILURE, errors };
  }
  function cleanState() {
    return { type: userConstants.CLEAN_STATE };
  }
}

function changePassword(clean, password, new_password, re_new_password) {
  return dispatch => {
    if (clean) {
      dispatch(cleanState());
    } else {
      dispatch(request(password));

      userService.changePassword(password, new_password, re_new_password).then(
        password => {
          dispatch(success(password));
        },
        errors => {
          dispatch(failure(errors));
        }
      );
    }
  };

  function request(password) {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST, password };
  }
  function success(password) {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS, password };
  }
  function failure(errors) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, errors };
  }
  function cleanState() {
    return { type: userConstants.CLEAN_STATE };
  }
}

function showInMap(consent) {
  return dispatch => {
    dispatch(request(consent));

    userService.show_in_map(consent).then(
      show_in_map => {
        dispatch(success(show_in_map.show_in_map));
      },
      errors => {
        dispatch(failure(errors));
      }
    );
  };

  function request(consent) {
    return { type: userConstants.PHOTO_CONSENT_REQUEST, consent };
  }
  function success(consent) {
    return { type: userConstants.PHOTO_CONSENT_SUCCESS, consent };
  }
  function failure(errors) {
    return { type: userConstants.PHOTO_CONSENT_FAILURE, errors };
  }
}

function updatePhoto(userId, avatar) {
  return dispatch => {
    dispatch(request(avatar));

    userService.updatePhoto(userId, avatar).then(
      avatar_response => {
        dispatch(success(avatar_response));
      },
      errors => {
        dispatch(failure(errors));
      }
    );
  };

  function request(avatar) {
    return { type: userConstants.PHOTO_UPDATE_REQUEST, avatar };
  }
  function success(avatar) {
    return { type: userConstants.PHOTO_UPDATE_SUCCESS, avatar };
  }
  function failure(errors) {
    return { type: userConstants.PHOTO_UPDATE_FAILURE, errors };
  }
}
