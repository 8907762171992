import React, { PureComponent as Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { ModalContainer, ModalRoute } from "react-router-modal";

import { loadReCaptcha } from 'react-recaptcha-v3'

import { Router } from "react-router-dom";
import { history } from "../../helpers";

import Header from "../../components/Header";
import HomeStartScreen from "../../components/HomeStartScreen";
import Footer from "../../components/Footer";

import LoginPage from "../../pages/Login";
import RegisterPage from "../../pages/Register";
import Forgot from "../../pages/Forgot";
import Account from "../../pages/Account";

import PrivateRoute from "../../components/PrivateRoute";

import { Home, SettingsSecurity, SettingsPhoto } from "../../pages";
import FaqPage from "../../pages/Faq";
import NewsPage from "../../pages/News";
import ManifestPage from "../../pages/Manifest";
import "react-router-modal/css/react-router-modal.css";
import "./App.css";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import OrderForm from "../Order";

class App extends Component {
  componentDidMount() {
    loadReCaptcha(process.env.REACT_APP_GR_CAPTCHA_SITE_KEY);
  }

  render() {
    return (
      <Router history={history}>
        <Header absolute={true} invert={true} />
        <HomeStartScreen {...this.props}/>
        <Switch>
          {/* <Route
            exact
            path="/"
            render={props => <Home {...props} scrollToContent={true} />}
          /> */}

          <Route exact path="/" component={Home} />
          <Route exact path="/faq" component={FaqPage} />
          <Route exact path="/news" component={NewsPage} />
          <Route exact path="/manifest" component={ManifestPage} />
          <ModalRoute path="/register" parentPath="/" exact>
            <RegisterPage />
          </ModalRoute>

          <ModalRoute path="/login" parentPath="/" exact>
            <LoginPage />
          </ModalRoute>
          <ModalRoute path="/forgot" parentPath="/" exact>
            <Forgot />
          </ModalRoute>
          <PrivateRoute
            exact
            path="/account"
            parentPath="/"
            component={Account}
          />
          <PrivateRoute
              exact
              path="/order"
              parentPath="/"
              component={OrderForm}
          />
          <Redirect exact from="/settings" to="/settings/photo" />
          <PrivateRoute
            exact
            path="/settings/photo"
            parentPath="/"
            component={SettingsPhoto}
          />
          <PrivateRoute
            exact
            path="/settings/security"
            parentPath="/"
            component={SettingsSecurity}
          />

          {/* <ModalRoute path="/account" parentPath="/" exact> */}
        </Switch>
        <ModalContainer />
        <Footer />
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.user_data;
  return {
    user,
  };
}

const connectedApp = connect(mapStateToProps)(App);

export default withTranslation()(connectedApp);