import React, { PureComponent as Component } from "react";

import HomeDescription from "./../components/HomeDescription";
import HomeManifest from "./../components/HomeDescription/HomeManifest";
import HomeVideo from "./../components/HomeDescription/HomeVideo";
import DescriptionTabsBlock from "./../components/HomeDescription/DescriptionTabsBlock";

import { ScrollToHOC } from "react-scroll-to";

// import ym from "react-yandex-metrika";

class Home extends Component {
  componentDidMount() {
    const { scrollToContent } = this.props.location;
    if (scrollToContent) {
      this.handleScrollToContent();
    }

    // ym("hit", "/");
  }

  componentDidUpdate() {
    const { scrollToContent } = this.props.location;
    if (scrollToContent) {
      this.handleScrollToContent();
    }
  }

  handleScrollToContent = () => {
    const { scrollTo } = this.props;
    const windowHeight = window.innerHeight;
    const windowWidth = window.screen.innerWidth;
    if (windowWidth < 768) {
      scrollTo({ y: windowHeight - 80, smooth: true });
    } else {
      scrollTo({ y: windowHeight, smooth: true });
    }
  };

  render() {
    return (
      <>
        <HomeDescription />
        <DescriptionTabsBlock />
        <HomeManifest />
        <HomeVideo></HomeVideo>
      </>
    );
  }
}

export default ScrollToHOC(Home);
