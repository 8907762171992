import {orderService} from "../services";
import {history} from "../helpers";
import {orderConstants} from "../constants";

export const orderActions = {
    checkAddress,

};

function checkAddress(data) {

    return dispatch => {
        dispatch(request());

        orderService.checkAddress(data).then(
            data => {
                dispatch(success(data));
            },
            errors => {
                dispatch(failure(errors));
            }
        );
    };

    function request(user) {
        return { type: orderConstants.CHECK_ADDRESS_REQUEST };
    }
    function success(data) {
        return { type: orderConstants.CHECK_ADDRESS_SUCCESS, data };
    }
    function failure(errors) {
        return { type: orderConstants.CHECK_ADDRESS_FAILURE, errors };
    }
}