export default {
    headline: {
      ru: { text: "Декларация по проблемам радикального продления жизни" },
      en: { text: "Declaration on Problems of Radical Life Extension" },
    },
    ru: {
        introduction: {
            text: [
                `
                <p>
                    В системе международно-правовых стандартов право на жизнь является фундаментальной ценностью человека. 
                    Мы, граждане и основатели децентрализованного экстерриториального государства <a href="/">Vitaspace</a>, 
                    а также участники проекта <a href="https://vitacoin.net/ru/" target="_blank">Vitacoin</a>, считаем, что старение и смерть явно нарушают это право, 
                    и призываем сплотиться всех людей вокруг идеи радикального продления жизни.
                </p>
                `,
                `<p>
                    <span>Принимая во внимание</span>, что население во всем мире беспрецедентно стареет и умирает,
                </p>`,
                `<p>
                <span>принимая во внимание также</span>, 
                    что абсолютно все люди и государства должны объединиться в 
                    борьбе против двух врагов — старения и смерти, 
                    и объявить эту задачу первостепенной,
                </p>`,
                `<p>
                <span>признавая</span>, что старение является заболеванием, которое несет огромную опасность для жизни человека,
                </p>`,
                `<p>
                    <span>сознавая</span>, что необходимы коренные изменения в политике всех 
                    государств относительно мер по профилактике и лечению старения и возрастных заболеваний, 
                </p>`,
                `<p>
                    <span>сознавая также</span>, что необходимы существенные изменения в сознании человека касательно 
                    старения и смерти как неизбежных и обязательных явлений в жизни человека,
                </p>`,
                `<p>
                    <span>граждане Vitaspace</span> и <span>участники проекта Vitacoin провозглашают</span> настоящую 
                    <span>Декларацию по проблемам радикального продления жизни</span> в качестве задачи, 
                    к выполнению которой должны стремиться все люди и правительства всех стран.

                </p>`,
            ]
        },
        chaptersList: [ 
            {
                headline: "I. Права человека на радикальное продление жизни",
                clauses: [
                    {
                        headline: `Статья 1`,
                        text: `<p>Каждый человек вне зависимости от расы, пола, языка, религии, политических или иных убеждений, 
                                национального или социального происхождения, имущественного, сословного или иного положения имеет 
                                право на долгую жизнь без болезней. Никто не может отнять у человека это право. </p>`
                    },
                    {
                        headline: `Статья 2`,  
                        text: `<p>Старение — такая же болезнь, как и остальные. Поэтому каждый человек имеет право бороться с ним, 
                        стремиться к лучшему состоянию здоровья и добиваться его, использовать для этого все возможные ресурсы, 
                        а в случае изобретения лекарства — получить таковое. </p>`,
                    },
                    {
                        headline: `Статья 3`,  
                        text: `<p>Человек имеет право на то, чтобы не мириться с неизбежностью старости и любыми другими негативными 
                        стереотипами о старении, не признавать того, что смерть — это обязательный и неотвратимый конец жизни. </p>`,
                    },
                    {
                        headline: `Статья 4`,  
                        text: `
                        <ol>
                            <li>Человек имеет право на то, чтобы изменить эволюционную «программу» и не идти по ходу «естественного» 
                            течения биологической жизни, а выбрать направление этого движения — то есть сделать со все возможное, чтобы 
                            замедлить процесс старения, продлить жизнь и молодость.
                            </li>
                            <li>Человек имеет право на применение к нему самых последних эффективных медицинских технологий, 
                            в том числе инструментов генной терапии, регенеративной медицины, нанотехнологий, и получение помощи 
                            от высококвалифицированных специалистов.
                            </li>
                            <li>Человек имеет право на любые изменения собственного тела, которые помогут ему продлить жизнь и дольше 
                            оставаться молодым, но только в том случае, если применяемая методика одобрена и никому не причинит вреда.
                            </li>
                            <li>Специалисты, в свою очередь, имеют право применять различные методы, направленные на увеличение 
                            продолжительности жизни, с письменного согласия заранее проинформированного пациента.
                            </li>
                        </ol>
                        `,
                    },
                    {
                        headline: `Статья 5`,  
                        text: `<ol>
                        <li>
                            Человек имеет право на получение достоверной информации о новейших достижениях в сфере медицины, геронтологии, 
                            генетики и других наук, так или иначе касающихся старения, продления жизни и молодости, а также о том, как 
                            правильно использовать собственные ресурсы организма, чтобы улучшить здоровье и увеличить продолжительность жизни.
                        </li>
                        <li>
                            Человек имеет право применить все эти знания на практике.
                        </li>
                        </ol>`,
                    },
                    {
                        headline: `Статья 6`,  
                        text: `<ol>
                        <li>
                            Никто не может быть подвергнут осуждению/наказанию/преследованию/изгнанию за желание жить дольше и за действия, 
                            которые были, есть или будут предприняты для замедления процесса старения.
                        </li>
                        <li>
                            Каждый имеет право на защиту своих интересов относительно старения и увеличения продолжительности жизни.
                        </li>
                        </ol>`,
                    },
                    {
                        headline: `Статья 7`,  
                        text: `<p>Ученые имеют право проводить любые исследования, которые направлены на то, чтобы победить старение, 
                        продлить молодость и увеличить продолжительности жизни человека и человечества в целом.</p>
                        `,
                    },
                    {
                        headline: `Статья 8`,  
                        text: `<p>В ряде случаев приходится сталкиваться с возражениями увеличению продолжительности жизни: 
                        например, с тем, что долгая жизнь приведет к скуке, потеряет свой смысл и пр. 
                        Поскольку подобные высказывания носят субъективный характер, человек вправе сам решать, 
                        что приносит радость, придает жизни смысл и т. д. </p>
                        `,
                    },
                    {
                        headline: `Статья 9`,  
                        text: `<p>Человек имеет право вступить в сообщество по интересам, объединить свои силы, идеи и ресурсы 
                        с теми, кто также заинтересован в победе над старением и продлении активной жизни, 
                        с целью поиска успешных решений и обеспечения лучшего будущего для всех. </p>
                        `,
                    },
                    {
                        headline: `Статья 10`,  
                        text: `<p>Человек имеет право принимать участие в создании и поддержке научных проектов, осуществлении программ, 
                        целевом финансировании новых разработок для радикального продления жизни, 
                        а также поощрять средства массовой информации к тому, чтобы они играли ключевую роль 
                        в информировании о проблемах старения, продления жизни и молодости населения. </p>`,
                    },
                ] 
            },
            {
                headline: `II. Инициативы по радикальному продлению жизни`,
                clauses: [
                    {
                        headline: ``,  
                        text: `<p>Граждане Vitaspace и  участники проекта Vitacoin признают необходимость практической 
                                стратегии деятельности по проблемам радикального продления жизни и настоятельно призывают международное 
                                сообщество поддерживать инициативы, связанные с решением этих проблем. Среди таких:
                                </p>
                                <ol class="big__list">
                                    <li>признание радикального продления жизни и молодости реальной возможностью, а не недостижимой целью;</li>
                                    <li>расширение международного сообщества — объединение ученых, представителей СМИ, коммерческих предпринимателей, членов правительственных структур, 
                                    а также всех неравнодушных людей вокруг идеи радикального продления жизни и молодости;
                                    </li>
                                    <li>создание международного Научного центра — главной мировой площадки для разработки лекарств и 
                                    технологий радикального продления жизни, объединяющей усилия различных направлений науки, таких как медицина, 
                                    фармацевтика, биотехнологии, IT, искусственный интеллект, робототехника и пр.;
                                    </li>
                                    <li>создание Целевого фонда по проблемам радикального продления жизни как источника поддержки международного Научного центра;</li>
                                    <li>популяризация знаний о радикальном продлении жизни: поддержание средств массовой информации, 
                                    научно-популярной литературы, научных лекций, конференций и прочего — с целью обеспечения широкого 
                                    распространения достоверной информации по проблемам радикального продления жизни среди населения;
                                    </li>
                                    <li>обеспечение доступности этих знаний для всех слоев населения 
                                    (например, предоставление бесплатного доступа ко всем научным статьям о продлении жизни и молодости);
                                    </li>
                                    <li>ведение пропаганды продления активной здоровой жизни и молодости в государственных и негосударственных 
                                    учреждениях;
                                    </li>
                                    <li>установление темы радикального продления жизни как главнейшей на всех крупных научных мероприятиях;                                    </li>
                                    <li>создание базы научных исследований о радикальном продлении жизни на единой платформе;
                                    </li>
                                    <li>создание специализированной пациентской организации, которая позволит тестировать новейшие неинвазивные способы продления жизни в короткие сроки;
                                    </li>
                                    <li>содействие межнациональному сотрудничеству и обмену знаниями и ресурсами между государствами для осуществления проектов и программ по продлению жизни и молодости;
                                    </li>
                                    <li>побуждение населения к изменению традиционных взглядов на старение и смерть;</li>
                                    <li>поощрение населения к развитию физических, социальных и духовных качеств, связанных с продлением жизни и молодости; </li>
                                    <li>воспитание новой личности — такой, которая не только знает, но и умеет жить в мире, где не существует возрастных границ, где долгожитель не является обузой для семьи или государства, где можно вести активную жизнь даже в 150 лет.
                                    </li>
                                </ol>`,
                    },
                ]
            }
        ]

    },
  
    en: {
        introduction: {
            text: [
                `
                <p>
                According to international legal standards, the right to life is a fundamental human value. We, the citizens and founders 
                of the decentralized extraterritorial State of <a href="/">Vitaspace</a>, and also <a href="https://vitacoin.net/en/" target="_blank">Vitacoin</a> project participants, 
                believe that ageing and death violate this right evidently, and we call on all people to unite around the idea of a radical life extension.
                </p>
                `,
                `<p>
                    <span>Taking into account</span> that populations around the world are ageing and dying in unprecedented ways,
                </p>`,
                `<p>
                <span>likewise</span>, considering that all the people and states must join together
                 to fight against ageing and death, and declare this issue as a priority,
                </p>`,
                `<p>
                    <span>admitting</span>, that ageing is a disease jeopardizing human life,
                    , 
                </p>`,
                `<p>
                    <span>awaring</span> that fundamental changes are needed in the 
                    policies of all States with regard to the prevention and treatment of ageing and age-related diseases, 
                </p>`,
                `<p>
                    <span>either awaring</span> the fact that significant changes in human consciousness 
                    are necessary with regard to ageing and death as inevitable and obligatory phenomena in human life,
 
                </p>`,
                `<p>
                    <span>Vitaspace citizens</span> and <span>Vitacoin project members proclaim</span> this 
                    <span>Declaration on Radical Life Extension</span> as a goal to be pursued by all individuals and governments of all countries.


                </p>`,
            ]
        },
        chaptersList: [ 
            {
                headline: "I. Human right to radical life extension",
                clauses: [
                    {
                        headline: `Article 1`,
                        text: `<p>Everyone has the right to a long life without diseases, irrespective of race, sex, language, religion, political or other opinion, national or social origin, property, birth or another status. No one may take away this right from a person.</p>`
                    },
                    {
                        headline: `Article 2`,  
                        text: `<p>Ageing is as much a disease as the others. Therefore, everyone has the right to fight it, to strive for and achieve better health, to use all possible resources for this purpose, and in case of the invention of medicine - to get one. </p>`,
                    },
                    {
                        headline: `Article 3`,  
                        text: `<p>Human has the right not to come to terms with the inevitability of any negative stereotypes about ageing, not to admit that death is an obligatory end of life.</p>`,
                    },
                    {
                        headline: `Article 4`,  
                        text: `
                        <ol>
                            <li>A person has the right to alter the evolutionary "program" and not to follow the "natural" flow of biological life, 
                            but to opt for the direction. That is, to do everything possible for slowing down ageing and prolonging life and youth.
                            </li>
                            <li>A person has the right to apply the latest effective medical technologies, including tools of gene therapy, 
                            regenerative medicine, nanotechnology, and receive assistance from highly qualified specialists.
                            </li>
                            <li>A person has the right to any options to his or her own body that will help prolong life and stay young longer, 
                            but only if the method used is approved and not harmful to anyone.
                            </li>
                            <li>Specialists have the right to apply various methods aimed at increasing life expectancy, with the written consent of an informed patient.
                            </li>
                        </ol>
                        `,
                    },
                    {
                        headline: `Article 5`,  
                        text: `<ol>
                        <li>
                        A person has the right to receive reliable information about the latest developments in medicine, gerontology, 
                        genetics and other sciences, in one way or another, related to ageing, 
                        life extension and youth, as well as how to properly use their own body resources to improve health and increase life expectancy.
                        </li>
                        <li>
                            An individual has the right to apply all this knowledge in practice.
                        </li>
                        </ol>`,
                    },
                    {
                        headline: `Article 6`,  
                        text: `<ol>
                        <li>
                            Anyone cannot be judged/punished/persecuted/exiled for wanting to live 
                            longer and for actions that have been, still or will be taken to slow down ageing.
                        </li>
                        <li>
                            Everyone has the right to protect their interests regarding ageing and increased longevity.
                        </li>
                        </ol>`,
                    },
                    {
                        headline: `Article 7`,  
                        text: `<p>Scientists have the right to carry out any research that aims to defeat ageing, 
                        prolong youth and increase life expectancy for humans and humanity as a whole.
                        </p>
                        `,
                    },
                    {
                        headline: `Article 8`,  
                        text: `<p>There are objections to longer life spans: for instance, that long life will result in boredom, 
                        lose its meaning, and so on. Since such statements are subjective, a person has the right to decide 
                        personally what brings joy, gives life meaning, etc.
                        </p>
                        `,
                    },
                    {
                        headline: `Article 9`,  
                        text: `<p>The person has the right to join a community of interest, 
                        to join forces, ideas and resources with those who are also interested in defeating 
                        ageing and prolonging active life, in order to find successful solutions and ensure a better future for all.
                        </p>
                        `,
                    },
                    {
                        headline: `Article 10`,  
                        text: `<p>Human beings have the right to participate in the creation and support of scientific projects, 
                        the implementation of programmes, the targeted funding of new developments to radically prolong life and to encourage the 
                        media to play a key role in informing about the problems of ageing, life extension and youth.</p>`,
                    },
                ] 
            },
            {
                headline: `II. Radical life extensions’ activities`,
                clauses: [
                    {
                        headline: ``,  
                        text: `<p>Vitaspace citizens and Vitacoin project participants admit the necessity for 
                        having a strategy to overcome the challenges of radical life prolongation and urge the 
                        international community to support activities solving these issues. Such activities include the following:
                        </p>
                        <ol class="big__list">
                            <li>the recognition of radical life prolongation and youth as real opportunities rather than unachievable goals;
                            </li>
                            <li>expansion of the international community - bringing together scientists, the media, businessmen, 
                            government officials and all those who care about radical prolongation of life and youth;</li>
                            <li>creation of the International Scientific Center - the main world platform for the development of drugs and technologies for radical prolongation of life, combining the efforts of different areas of science, such as medicine, pharmaceutics, biotechnology, IT, artificial intelligence, robotics, etc.;
                            </li>
                            <li>the establishment of the Trust Fund for Radical Life Extension to support the International Scientific Centre;</li>
                            <li>the popularization of knowledge on radical prolongation of life: endorsement of mass media, popular science books, scientific lectures, conferences, etc. to ensure distribution of reliable information on problems of radical prolongation of life among the population;
                            </li>
                            <li>making this knowledge accessible for each and every (e.g. providing free access to all scientific articles on life extension and youth);
                            </li>
                            <li>advocating for the prolongation of active healthy life and youth in State and non-State institutions;
                            </li>
                            <li>declaring the radical life prolongation as by far the most important theme at all major scientific events;</li>
                            <li>creation of the R&D community on radical prolongation of life on a single platform;</li>
                            <li>creation of a patient organization, which will allow testing the brand new non-invasive methods of life extension in a short time;                            </li>
                            <li>assistance in international cooperation and the exchange of knowledge and resources between States for the implementation of projects and programmes to extend life and youth;</li>
                            <li>encouraging the population to change traditional point of view on ageing and death;</li>
                            <li>encouraging the population to develop the physical, social and personal skills associated with life extension and youth;</li>
                            <li>fostering a new person - the one who not only knows but also understands how to live in a world without age limits, where longevity is not a burden for the family or the state, where one can live an active life even at the age of 150.</li>
                        </ol>
                        `,
                    },
                ]
            }
        ]
    }
}