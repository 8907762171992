export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));
  let lang = localStorage.getItem("i18nextLng");

  let headers = {};

  if (user && user.token) {
    headers["Authorization"] = "JWT " + user.token;
  }

  if (lang) {
    headers["Accept-Language"] = lang;
  }
  return headers;
}
