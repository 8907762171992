import React from "react";
import ReactHtmlParser from "react-html-parser";

import { useTranslation } from "react-i18next";

import { Row, Col } from "react-flexbox-grid";

import { Head2, Grid, DescriptionBlock, ButtonP, OrderButton } from "./styled";

export default () => {
  const { t } = useTranslation();

  return (
    <DescriptionBlock>
      <Grid>
        <Row>
          <Col xs={12}>
            <Head2>{t("home.manifest.headline")}</Head2>
            <p>{ReactHtmlParser(t("home.manifest.text.1"))}</p>
            <p>{ReactHtmlParser(t("home.manifest.text.2"))}</p>
            <p>{ReactHtmlParser(t("home.manifest.text.3"))}</p>
            <p>{ReactHtmlParser(t("home.manifest.text.4"))}</p>
            <p>{ReactHtmlParser(t("home.manifest.text.5"))}</p>
            <p>{ReactHtmlParser(t("home.manifest.text.6"))}</p>
            <p>{ReactHtmlParser(t("home.manifest.text.7"))}</p>
            <ButtonP>
                <OrderButton to={{ pathname: "/manifest", scrollToContent: true }}>
                    {t("readMore")}
                </OrderButton>
            </ButtonP>
          </Col>
        </Row>
      </Grid>
    </DescriptionBlock>
  );
};
