import React, { PureComponent as Component } from "react";
import { withTranslation } from "react-i18next";
import { Spinner } from "../Spinner";

class Loading extends Component {
  render() {
    const { t, light, big } = this.props;
    return (
      <>
        <div>
          <Spinner light={light} big={big} />
        </div>
        <div style={{ marginTop: "8px" }}>{t("loading")} ...</div>
      </>
    );
  }
}

export default withTranslation()(Loading);
