import { userConstants } from "../constants";

export function change_password(state = {}, action) {
  switch (action.type) {
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        saving: true
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        data: action.message
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        error: action.errors
      };
    case userConstants.CLEAN_STATE:
      return {};
    default:
      return state;
  }
}
