/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { PureComponent as Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";

import { withTranslation } from "react-i18next";
import { languages } from "../../languages/languages";

import {
  FooterSection,
  SocIcons,
  ProjectIcons,
  FooterLinks,
  FooterText
} from "./styled";

import instagram_img from "./vinst.svg";
import fb_img from "./vfb.svg";
import vk_img from "./vvk.svg";
import senator_img from "./vsenator-logo.svg";
import ivao_img from "./vivao_logo.svg";
import vitacoin_img from "./vvitacoin-logo_new.svg";
import twitter_img from "./twitter.svg";
import UsersGraph from "./Graph";

class Footer extends Component {
  getCurrentLang = () => {
    const i18nextLng = localStorage.getItem("i18nextLng");
    return languages.find(lang => i18nextLng === lang.code);
  };

  render() {
    const { t } = this.props;
    const date = new Date();
    const current_lang = this.getCurrentLang();

    return (
      <FooterSection>
        <UsersGraph></UsersGraph>
        <Grid>
          <Row middle="xs">
            <Col xs={6}>
              <SocIcons>
                <a href="#">
                  <img src={instagram_img} alt="" />
                </a>

                <a
                  target="_blank"
                  href="https://www.facebook.com/pages/category/Cause/Vitaspace-406580459927024/"
                >
                  <img src={fb_img} alt="" />
                </a>
                <a target="_blank" href="https://vk.com/vitaspace25">
                  <img src={vk_img} alt="" />
                </a>
                <a target="_blank" href="https://twitter.com/IvaoAnd">
                  <img height={34} src={twitter_img} alt="" />
                </a>
              </SocIcons>
            </Col>

            <Col xs={6}>
              <ProjectIcons>
                <a target="_blank" href="https://senator.spb.ru/">
                  <img height={48} src={senator_img} alt="" />
                </a>
                <a target="_blank" href="https://ivao.com/">
                  <img height={18} src={ivao_img} alt="" />
                </a>
                <a target="_blank" href="https://vitacoin.net/">
                  <img height={38} src={vitacoin_img} alt="" />
                </a>
              </ProjectIcons>
            </Col>
          </Row>
          <FooterLinks>
            <Row between="sm">
              <Col xs={12} sm={4}>
                <FooterText align="left">
                  © 2014-{date.getFullYear()} IVAO, {t("footer.rights")}
                </FooterText>
              </Col>
              <Col xs={12} sm={4}>
                <FooterText align="center">
                  <a
                    target="_blank"
                    href={`https://vitaspace.net${current_lang &&
                      current_lang.code &&
                      `/${current_lang.code}`}/privacy-policy.html`}
                  >
                    {t("footer.policy")}
                  </a>
                </FooterText>
              </Col>
              <Col xs={12} sm={4}>
                <FooterText align="right">
                  <a
                    target="_blank"
                    href={`https://vitaspace.net${current_lang &&
                      current_lang.code &&
                      `/${current_lang.code}`}/terms-of-use.html`}
                  >
                    {t("footer.agreement")}
                  </a>
                </FooterText>
              </Col>
            </Row>
          </FooterLinks>
        </Grid>
      </FooterSection>
    );
  }
}

export default withTranslation()(Footer);
