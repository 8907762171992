import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./languages/i18n";
// import { store, configureFakeBackend } from "./helpers";
import { store } from "./helpers";

import { YMInitializer } from "react-yandex-metrika";

import App from "./components/App";

import "normalize.css";

// configureFakeBackend();

ReactDOM.render(
  <Provider store={store}>
    <YMInitializer
      accounts={[56733898]}
      options={{ webvisor: true }}
      version="2"
    />
    <App />
  </Provider>,
  document.getElementById("root")
);
