import {orderConstants} from "../constants";

const initialState = {
    address: {},
    calculatingAddress: false
}

export function order(state = initialState, action) {
    switch (action.type) {
        case orderConstants.CHECK_ADDRESS_REQUEST:
            return {
                ...state,
                calculatingAddress: true
            };
        case orderConstants.CHECK_ADDRESS_SUCCESS:
            return {
                ...state,
                address: action.data,
                calculatingAddress: false
            };
        case orderConstants.CHECK_ADDRESS_FAILURE:
            return {
                ...state,
                error: action.error,
                calculatingAddress: false
            };
        default:
            return {...state}
    }
}