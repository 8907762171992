import styled from "styled-components";
import arrow_left from "./left-chevron.svg";

export const ModalHeader = styled.div`
  padding: 16px;

  h2 {
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 1px;
  }
`;

export const ModalTabs = styled.div`
  display: flex;
  flex-direction: row;
  background: #19212b;
  margin-bottom: -16px;
  margin-left: -16px;
  margin-right: -16px;
`;
export const ModalTab = styled.div`
  width: 50%;
  text-align: center;
  color: ${({ active }) => (active ? "#d6b678" : "#e9f2f8")};
  cursor: pointer;

  border-bottom: ${props =>
    !!props.active && props.active ? "3px solid #d6b678" : "3px solid #19212b"};

  a,
  button {
    width: 100%;
    height: 100%;
    padding: 16px 8px;
    background: transparent;
    border: 0;
    outline: 0;
    text-decoration: none;
    color: ${({ active }) => (active ? "#d6b678" : "#e9f2f8")};
    display: block;
    cursor: pointer;
  }
`;

export const ModalInnerContent = styled.div`
  display: flex;
  flex-flow: column wrap;
  ${"" /* background: #26303c; */}
  background: #f5f5f5;
  padding: 24px;
  ${"" /* color: #e9f2f8; */}
  color: ##26303c
  border-radius: 16px;

  &:not(:first-child) {
    margin-top: 32px;
  }

  @media screen and (min-width: 768px) {
    ${"" /* max-width: 50%; */}
  }

  h3 {
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 4px;
    padding: 0;
  }
`;

export const ModalInputWrapper = styled.div`
  ${"" /* background: #26303c; */}
  background: #f5f5f5;
  ${"" /* color: #e9f2f8; */}
  color: #26303c;
  width: 100%;
  height: 48px;
  font-size: 15px;
  border-bottom: ${props =>
    props.active ? "1px solid #cccccc;" : "1px solid #cccccc;"};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  transition: 0.2s;
  position: relative;
  overflow: hidden;

  &:focus-within {
    border-bottom: 1px solid #a5a5a5;
  }
`;

export const ModalInputIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  margin-right: 8px;
`;

export const ModalInput = styled.input`
  background: transparent;
  color: #26303c;
  outline: 0;
  width: 100%;
  height: 100%;
  border: 0;
  font-size: 14px;
  padding-left: 4px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: transparent;
    box-shadow: 0 0 0px 1000px #f5f5f5 inset, 0 0 0px 1px #f5f5f5;
    -webkit-text-fill-color: ##26303c;
    border: 0;
    transition: background-color 5000s ease-in-out 0s;
    height: 90%;
  }

  &:disabled {
    color: #474f54;
    box-shadow: 0 0 0px 1000px #f5f5f5 inset, 0 0 0px 1px #f5f5f5;
    ${"" /* -webkit-text-fill-color: #545454; */}
  }
`;

export const Button = styled.button`
  background: ${props => (props.active ? "#d6b678" : "#cccccc")};
  color: ${props => (props.active ? "#26303c" : "#545454")};
  border: 0;
  border-radius: 3em;
  outline: none;
  padding: 12px 32px;
  font-size: 14px;
  cursor: ${props => (props.active ? "pointer" : "not-allowed")};
  display: block;
  text-decoration: none;
  text-align: center;
  transition: 0.5s;
  margin-left: auto;
  user-select: none;
`;

export const DisclaimerP = styled.p`
  color: #808080;
  font-size: 12px;
  padding: 0.4em 0;
  font-weight: 200;
  line-height: 1.4em;

  a {
    color: #808080;
  }
`;

export const ModalWarning = styled.span`
  position: absolute;
  background: #fbdfdf;
  padding: 4px 16px;
  top: calc(50% - 6px);
  border-radius: 16px;
  right: 0;
  display: ${props => (props.password_match ? "none" : "block")};
  transition: 0.3s ease-in-out;
  font-size: 12px;
  color: #f44336;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

export const ModalSuccess = styled.span`
  background: #ddffde;
  padding: 4px 16px;
  z-index: 9999;
  top: 48px;
  border-radius: 16px;
  height: 24px;
  line-height: 16px;
  left: 0;
  display: ${props => (props.password_changed ? "block" : "none")};
  transition: 0.3s ease-in-out;
  font-size: 12px;
  color: #4caf50;
`;

export const Options = styled.div`
  font-weight: 300;
  line-height: 1.4em;
  font-size: 15px;
  width: 100%;
  display: flex;
  flex-flow: column;
`;

export const Option = styled.div`
  margin-top: 8px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const OptionText = styled.div`
  margin-bottom: 5px;
  padding-right: 8px;
  font-size: 13px;

  @media screen and (min-width: 992px) {
    font-size: 15px;
  }
`;

export const PrevPage = styled.div`
  padding: 8px 16px;
  position: relative;
  cursor: pointer;

  a {
    text-decoration: none;
    color: #cccccc;

    &:before {
      content: "";
      position: absolute;
      background-image: url(${arrow_left});
      background-size: contain;
      background-position: center;
      height: 16px;
      width: 16px;
      top: calc(50% - 8px);
      left: -8px;
    }
  }
`;

export const PhotoViewBlock = styled.div`
  margin-top: 16px;
  text-align: center;

  img {
    border-radius: 1em;
    user-select: none;
    width: 200px;
    height: 200px;
  }
`;

export const UploadPhotoBlock = styled.div`
  margin-top: 16px;
  text-align: center;
`;

export const UploadPhotoButton = styled.button`
  font-size: 14px;
  user-select: none;
  outline: 0;
  border: 0;
  padding: 0.8em 1.4em;
  color: #8a6110;
  background: #d6b677;
  border-radius: 3em;
  cursor: pointer;
  transition: 0.2s;

  &:active {
    background: #caab6f;
  }
`;

export const RemovePhotoButton = styled.button`
  font-size: 14px;
  user-select: none;
  outline: 0;
  border: 0;
  padding: 0.8em 1.4em;
  ${"" /* color: #961c13; */}
  ${"" /* background: #f5574b; */}
         background: #cccccc;
  color: #545454;
  border-radius: 3em;
  cursor: pointer;
  transition: 0.2s;
  margin-left: 8px;
  margin-top: 8px;

  &:active {
    ${"" /* background: #e04e43; */}
    background: #bbbbbb;
  }
`;

export const Slider = styled.input`
  appearance: none;
  width: 100%;
  height: 4px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.8;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #d6b677;
    cursor: pointer;
    border-radius: 100%;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #d6b677;
    cursor: pointer;
    border-radius: 100%;
  }
`;

export const CropContainer = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  margin: 0 auto;
  border-radius: 1em;
  overflow: hidden;
`;

export const CropControls = styled.div`
  margin: auto;
  width: 200px;
  height: 80px;
  display: flex;
  align-items: center;
`;
