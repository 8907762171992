import React, { PureComponent as Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { userActions } from "../../../actions";

import password_icon from "./password.svg";
import err_icon from "./err.svg";
import ok_icon from "./ok.svg";

import {
  ModalWrapper,
  ModalContent,
  Grid,
  SystemMessage,
  Logout
} from "./../styled";
import { ModalHeader, ModalTabs, ModalTab } from "./styled";
import { Spinner } from "./../../Spinner";

import {
  ModalInnerContent,
  ModalInputWrapper,
  ModalInputIcon,
  ModalInput,
  ModalWarning,
  ModalFooter,
  ModalSuccess,
  Button,
  PrevPage
} from "./styled";

import logout_icon from "../logout_icon.svg";
import Loading from "../../Loading";

const initialState = {
  password: "",
  new_password: "",
  re_new_password: "",
  password_match: true,
  active: false,
  check_password: false,
  submitted: false,
  message: false,
  password_changed: false,
  success_message: "smthg ok"
};

class SecurityBlock extends Component {
  state = initialState;

  componentDidMount() {
    const { user, user_data, dispatch } = this.props;
    if (!user_data.data && user && user.id) {
      dispatch(userActions.getById(user.id));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { check_password } = this.props;
    const { data, error } = check_password;

    if (data && data.check_password && !prevState.check_password) {
      this.setState(prevState => ({
        check_password: true,
        message: false
      }));
    }
    if (error && error.message) {
      this.setState(prevState => ({
        message: error.message
      }));
    }

    if (data && data.message && !prevState.password_changed) {
      this.setState(prevState => ({
        ...initialState,
        password: "",
        new_password: "",
        re_new_password: "",
        password_match: true,
        active: false,
        check_password: false,
        submitted: false,
        message: false,
        password_changed: true,
        success_message: data.message
      }));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(userActions.checkPassword(true));
    this.props.dispatch(userActions.changePassword(true));
  }

  handleChange = e => {
    let { name, value } = e.target;
    if (name === "re_new_password") {
      if (this.state.new_password !== value) {
        this.setState({ password_match: false });
      } else {
        this.setState({ active: true });
        this.setState({ password_match: true });
      }
    }
    this.setState({ [name]: value });
  };

  focusOut = () => {
    const { password } = this.state;
    const { dispatch } = this.props;

    if (password) {
      dispatch(userActions.checkPassword(false, password));
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { password, new_password, re_new_password } = this.state;

    const { dispatch } = this.props;
    if (password && new_password && re_new_password) {
      dispatch(
        userActions.changePassword(
          false,
          password,
          new_password,
          re_new_password
        )
      );
    }
  };

  render() {
    const { t } = this.props;
    let loading = this.props.check_password.loading;
    let saving = this.props.change_password
      ? this.props.change_password.saving
      : false;

    const { user_data } = this.props;

    return (
      <ModalWrapper style={{ maxWidth: "576px" }}>
        <ModalHeader>
          <Grid>
            <PrevPage>
              <Link to="/">{t("settings.back.button")}</Link>
            </PrevPage>
            <h2>{t("settings.settings")}</h2>
          </Grid>
          <ModalTabs>
            <ModalTab>
              <Link to="/settings/photo">{t("settings.photo")}</Link>
            </ModalTab>
            <ModalTab active={true}>
              <Link to="/settings/security">{t("settings.security")}</Link>
            </ModalTab>
          </ModalTabs>
        </ModalHeader>
        <ModalContent>
          <Grid>
            {user_data.loading && (
              <SystemMessage>
                <Loading light big />
              </SystemMessage>
            )}
            {user_data.error && (
              <>
                <SystemMessage>{t("error")}.</SystemMessage>
                <SystemMessage>
                  <Logout to="/login">
                    <span>{t("auth.sign.out")}</span>
                    <img src={logout_icon} alt="" />
                  </Logout>
                </SystemMessage>
              </>
            )}
            {!user_data.loading &&
              !user_data.error &&
              user_data &&
              user_data.data &&
              user_data.data.profile && (
                <ModalInnerContent>
                  <h3>{t("settings.change_password")}</h3>
                  <form onSubmit={this.handleSubmit}>
                    <ModalInputWrapper active={!this.state.check_password}>
                      {loading ? (
                        <Spinner />
                      ) : this.state.check_password ? (
                        <ModalInputIcon src={ok_icon} alt="" />
                      ) : (
                        <ModalInputIcon
                          src={!this.state.message ? password_icon : err_icon}
                          alt=""
                        />
                      )}
                      <ModalInput
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        placeholder={t("settings.old_password")}
                        value={this.state.password}
                        onChange={this.handleChange}
                        onBlur={this.focusOut}
                        disabled={this.state.check_password}
                      />
                      <ModalWarning password_match={!this.state.message}>
                        {this.state.message}
                      </ModalWarning>
                    </ModalInputWrapper>
                    <ModalInputWrapper active={this.state.check_password}>
                      <ModalInputIcon
                        src={
                          this.state.password_match ? password_icon : err_icon
                        }
                        alt=""
                      />
                      <ModalInput
                        id="new_password"
                        name="new_password"
                        type="password"
                        autoComplete="new-password"
                        placeholder={t("settings.new_password")}
                        onChange={this.handleChange}
                        disabled={!this.state.check_password}
                        value={this.state.new_password}
                      />
                    </ModalInputWrapper>
                    <ModalInputWrapper active={this.state.check_password}>
                      <ModalInputIcon
                        src={
                          this.state.password_match ? password_icon : err_icon
                        }
                        alt=""
                      />
                      <ModalInput
                        id="re_new_password"
                        name="re_new_password"
                        type="password"
                        autoComplete="new-password"
                        placeholder={t("settings.re_new_password")}
                        onChange={this.handleChange}
                        disabled={!this.state.check_password}
                        value={this.state.re_new_password}
                      />
                      <ModalWarning password_match={this.state.password_match}>
                        Passwords do not match
                      </ModalWarning>
                    </ModalInputWrapper>
                    <ModalFooter>
                      <ModalSuccess
                        password_changed={this.state.password_changed}
                      >
                        {this.state.success_message}
                      </ModalSuccess>
                      <Button
                        disabled={!this.state.active}
                        active={this.state.active}
                        to="/"
                      >
                        {saving ? <Spinner /> : t("settings.save.button")}
                      </Button>
                    </ModalFooter>
                  </form>
                </ModalInnerContent>
              )}
          </Grid>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {
    registration,
    authentication,
    check_password,
    change_password,
    user_data
  } = state;
  const { user } = registration.user ? registration : authentication;
  return {
    user,
    check_password,
    change_password,
    user_data
  };
}

const connectedSecurityBlock = connect(mapStateToProps)(SecurityBlock);
export default withTranslation()(connectedSecurityBlock);
