import React , { PureComponent as Component } from "react";
import { withTranslation } from "react-i18next";

import { HomeStartSection, LogoImg, LogoBlock, LogoCaption, LogoButton, ButtonP } from "./styled";

import logo from "./new_big_logo.svg";
import {loadReCaptcha} from "react-recaptcha-v3";

class  HomeStartScreen extends Component {
    componentDidMount() {
        loadReCaptcha(process.env.REACT_APP_GR_CAPTCHA_SITE_KEY);
    }

    render() {
        const {t, data} = this.props
        console.log(data)
        return (
            <HomeStartSection>
                <LogoBlock>
                    <LogoImg src={logo} alt="VitaSpace"/>
                    <LogoCaption>{t("description")}</LogoCaption>
                    <LogoButton to="/order">
                        <ButtonP>{t("join")}</ButtonP>
                    </LogoButton>
                </LogoBlock>
            </HomeStartSection>
        );
    }
}

export default withTranslation()(HomeStartScreen);
