import styled from "styled-components";
import { Grid as RFGrid } from "react-flexbox-grid";

export const NewsBlock = styled.section`
  color: #26303c;
  padding: 0 16px 64px 16px;

  h1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 40px;
    border-bottom: 4px solid #d6b678;
    padding-bottom: 12px;
    margin-top: 36px;
    margin-bottom: 24px;
    width: 100%;
  }
`;

export const Grid = styled(RFGrid)`
  @media only screen and (min-width: 768px) {
    width: 752px !important;
    max-width: 100%;
  }
`;

export const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 32px;
`;

export const ArticleContentWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(182, 177, 177, 0.5);
  border-radius: 4px;
  padding: 18px 32px;
  color: #4c4c4c;
  transition: 0.2s;
  width: 100%;

  &:hover {
    box-shadow: 0px 2px 4px rgba(182, 177, 177, 0.5),
      0px 0px 16px rgba(182, 177, 177, 0.2);
  }
`;

export const ArticleDateWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;
  font-style: italic;
`

export const ArticleText = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.4em;
  cursor: pointer;
  padding-right: 4px;

  &:before {
    position: absolute;
    font-weight: 300;
    font-size: 24px;
    content: "›";
    top: 0;
    right: -8px;
    transition: 0.2s;
    transform: rotate(${({ active }) => (active ? "90deg" : "0")});
  }
`;

export const ArticleAnswer = styled.div`
  margin-top: 24px;
  font-weight: 300;
  font-size: 15px;
  cursor: initial;
  line-height: 1.4em;
`;
