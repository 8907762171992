/* eslint-disable react/jsx-no-target-blank */
import React, { PureComponent as Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { userActions } from "../../actions";
import { ReCaptcha } from "react-recaptcha-v3";
import ym from "react-yandex-metrika";

import {
  ModalWrapper,
  ModalHeader,
  Logo,
  ModalTabs,
  ModalTab,
  ModalContent,
  ModalInputWrapper,
  ModalInputIcon,
  ModalInput,
  Button,
  DisclaimerP,
  ErrorsList,
  SuccessList
} from "./styled";

import { Spinner } from "../Spinner";

import logo from "./gerb_web_mh.svg";
import email_icon from "./email.svg";

class ForgotForm extends Component {
  state = {
    email: "",
    password: "",
    submitted: false,
    recaptchaToken: ""
  };

  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());
  }

  componentDidMount = () => {
    ym("hit", "/forgot");
  };

  verifyCallback = recaptchaToken => {
    this.setState({ recaptchaToken: recaptchaToken });
  };

  handleChange = e => {
    let { name, value } = e.target;
    if (name === "email") {
      value = value.toLowerCase();
    }
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { email, recaptchaToken } = this.state;

    const { dispatch } = this.props;
    if (email) {
      dispatch(userActions.forgot(email, recaptchaToken));
      this.setState(prevState => ({
        submitted: true,
        email: "",
        recaptchaToken: ""
      }));
      this.recaptcha.execute();
    }
  };

  render() {
    // const { loggingIn } = this.props;
    const { t, errors, success, request } = this.props;
    const { email } = this.state;

    return (
      <ModalWrapper>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>
            <Logo src={logo} alt="VitaSpace" />
          </ModalHeader>
          <ModalTabs>
            <ModalTab>
              <Link to="/login">{t("auth.sign.in")}</Link>
            </ModalTab>
            <ModalTab active={true}>
              <Link to="/register">{t("auth.sign.up")}</Link>
            </ModalTab>
          </ModalTabs>
          <ModalContent>
            <h3>{t("auth.recovery")}</h3>
            {errors && errors.detail && errors.detail.length > 0 && (
              <ErrorsList>
                <li>{errors.detail}</li>
              </ErrorsList>
            )}
            {success && success.message && success.message.length > 0 && (
              <SuccessList>
                <li>{success.message}</li>
              </SuccessList>
            )}
            {errors &&
              Array.isArray(Object.keys(errors)) &&
              Object.keys(errors).length > 0 && (
                <ErrorsList>
                  {Object.keys(errors).map((key, idx) => (
                    <li key={idx}>{errors[key]}</li>
                  ))}
                </ErrorsList>
              )}

            <DisclaimerP>{t("auth.forgot.disclaimer")}</DisclaimerP>

            <ModalInputWrapper>
              <ModalInputIcon src={email_icon} alt="" />
              <ModalInput
                type="text"
                placeholder={t("auth.email")}
                id="email"
                name="email"
                value={email}
                onChange={this.handleChange}
              />
            </ModalInputWrapper>

            <ReCaptcha
              ref={ref => (this.recaptcha = ref)}
              sitekey={process.env.REACT_APP_GR_CAPTCHA_SITE_KEY}
              action="login"
              verifyCallback={this.verifyCallback}
            />
            {this.state.recaptchaToken.length > 0 ? (
              <DisclaimerP>
                {t("google.recaptcha.terms")}{" "}
                <a target="_blank" href="https://policies.google.com/privacy">
                  {t("google.recaptcha.terms.policy")}
                </a>{" "}
                {t("google.recaptcha.terms.and")}{" "}
                <a target="_blank" href="https://policies.google.com/terms">
                  {t("google.recaptcha.terms.terms")}
                </a>{" "}
                {t("google.recaptcha.terms.google")}
              </DisclaimerP>
            ) : (
              <DisclaimerP>
                <Spinner /> Loading reCaptcha ...
              </DisclaimerP>
            )}
          </ModalContent>
          <Button
            disabled={this.state.recaptchaToken.length === 0}
            active={this.state.recaptchaToken.length > 0}
            to="/account"
          >
            {request ? <Spinner /> : t("auth.forgot.recover")}
          </Button>
        </form>
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { request, success, errors } = state.forgot_password;
  return {
    request,
    success,
    errors
  };
}

const connectedForgotPage = connect(mapStateToProps)(ForgotForm);

export default withTranslation()(connectedForgotPage);
