import { authHeader } from "../helpers";
import { fetch } from "whatwg-fetch";

export const config = {
  apiUrl: "https://api.vitaspace.net/api"
};

export const userService = {
  login,
  logout,
  register,
  getById,
  checkPassword,
  changePassword,
  forgot,
  updatePhoto,
  show_in_map
};

function login(email, password, recaptchaToken) {
  const form_data = new FormData();
  form_data.append("email", email);
  form_data.append("password", password);
  form_data.append("recaptchaToken", recaptchaToken);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    // headers: { "Content-Type": "application/json" },
    // body: JSON.stringify({ email, password })
    body: form_data
  };

  return fetch(`${config.apiUrl}/users/api-token-auth/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));

      return user;
    });
}

function checkPassword(password) {
  const form_data = new FormData();
  form_data.append("password", password);

  const requestOptions = {
    method: "PATCH",
    headers: authHeader(),
    mode: "no-cors",
    body: form_data
  };

  return fetch(`${config.apiUrl}/users/check-password/`, requestOptions).then(
    handleResponse
  );
}

function changePassword(password, new_password, re_new_password) {
  const form_data = new FormData();
  form_data.append("password", password);
  form_data.append("new_password", new_password);
  form_data.append("re_new_password", re_new_password);

  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    mode: "no-cors",
    body: form_data
  };

  return fetch(`${config.apiUrl}/users/change-password/`, requestOptions).then(
    handleResponse
  );
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/user/${id}/`, requestOptions).then(
    handleResponse
  );
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: user
  };

  return fetch(`${config.apiUrl}/users/register/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));

      return user;
    });
}

function forgot(email, recaptchaToken) {
  const form_data = new FormData();
  form_data.append("email", email);
  form_data.append("recaptchaToken", recaptchaToken);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: form_data
  };

  return fetch(`${config.apiUrl}/users/reset-password/`, requestOptions).then(
    handleResponse
  );
}

function updatePhoto(userId, photo) {
  const form_data = new FormData();
  form_data.append("avatar", photo, `${userId}_avatar.png`);
  // form_data.append("avatar", null);

  const requestOptions = {
    method: "PATCH",
    headers: authHeader(),
    body: form_data
  };

  return fetch(`${config.apiUrl}/users/user/${userId}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      return user;
    });
}

function show_in_map(consent) {
  const form_data = new FormData();
  form_data.append("show_in_map", consent);
  // form_data.append("password", password);
  // form_data.append("recaptchaToken", recaptchaToken);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    // headers: { "Content-Type": "application/json" },
    // body: JSON.stringify({ email, password })
    body: form_data
  };

  return fetch(`${config.apiUrl}/users/show-in-map/`, requestOptions).then(
    handleResponse
  );
}

export function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }

      let errors = (data && data.errors) || response.statusText;

      if (data && !data.errors) {
        errors = data;
      }
      return Promise.reject(errors);
    }
    return data;
  });
}
