import React, { PureComponent as Component } from "react";
import { Col } from "react-flexbox-grid";
import ReactHtmlParser from "react-html-parser";

class Clauses extends Component {

  renderClauses(clauses, i) {
    return  <>
                <h3 key={i}>{clauses.headline}</h3>
                { ReactHtmlParser(clauses.text) }
            </>
    }

  render() {
    const { clauses } = this.props;
    
    return (
      <Col xs={12}>
        {clauses.length > 0 &&
          clauses.map((clause, i) => (
            this.renderClauses(clause, i)
          ))}
      </Col>
    );
  }
}

export default Clauses;