import React, { PureComponent as Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { withTranslation } from "react-i18next";
import { languages } from "../../languages/languages";
import Clauses from "./Clauses";
import { ManifestBlock, Grid } from "./styled";
import ReactHtmlParser from "react-html-parser";
import ManifestData from "./ManifestData";

class Manifest extends Component {
  getCurrentLang = () => {
    const i18nextLng = localStorage.getItem("i18nextLng");
    return languages.find(lang => i18nextLng === lang.code);
  };

  render() {
    const currentLang = this.getCurrentLang();
    let currentLangCode = currentLang.code;
    if (!["ru", "en", "es", "cn"].includes(currentLangCode)) {
      currentLangCode = "en";
    }

    return (
      <ManifestBlock>
        <Grid>
          <Row>
            <Col xs={12}>
              <h1>{ManifestData.headline[currentLangCode].text}</h1>
              {ManifestData[currentLangCode].introduction.text.map( (article, i) => 
                <>
                    {ReactHtmlParser(article)}
                </>
              )}
              {currentLangCode &&
                ManifestData[currentLangCode].chaptersList.length > 0 &&
                ManifestData[currentLangCode].chaptersList.map((chapter, i) => (
                    <>
                        <h2 key={i}>{chapter.headline}</h2>
                        <Clauses clauses={chapter.clauses}/>
                    </>
                ))}
            </Col>
          </Row>
        </Grid>
      </ManifestBlock>
    );
  }
}

export default withTranslation()(Manifest);
