export default {
  translation: {
    "header.account": "用户中心",
    "header.faq": "常见问题",
    "header.about": "About",

    founding_date: "国家创始日期",
    "description": "Vitaspace是世界上第一个权力下放的域外国家，参与者在一个共同的思想中团结一致-青年的成长和无疾病的积极生活。",
    "join": "加入",

    "auth.sign.in": "登录",
    "auth.sign.up": "注册",
    "auth.sign.out": "退出",
    "auth.email": "邮箱",
    "auth.name": "名字",
    "auth.surname": "姓",
    "auth.phone": "电话",
    "auth.password": "密码",

    "footer.rights": "保留所有权利",
    "footer.policy": "隐私政策",
    "footer.agreement": "服务条款",

    "registration.policy": `确认注册，意味着您同意处理个人信息，并同意用户协议条款。`,
    "registration.policy_full": `确认注册，意味着您同意<2>处理个人信息</2>，并同意用<6>户协议条款</6>。`,

    upload: "Upload",
    loading: "Loading",
    error: "Error",

    orderVitapass: "预定实体 VitaPass",
    "account.welcome": "欢迎，",
    "account.changeCaption": `为了修改 VitaPass 账户信息（上传新照片，修改姓名），请发送相应的申请至邮箱`,

    "home.description.1": `Vitaspace是世界上第一个权力下放的域外国家，参与者在一个共同的思想中团结一致-青年的成长和无疾病的积极生活。`,
    "home.description.2": `许多人认为自己是世界公民。我们还建议不限于将人分开的地理，国家或其他类别。`,
    "home.description.3": `Vitaspace将提供一个重写社会规则的机会。一个权力下放的国家的公民将共同努力，从根本上延长他们的寿命。`,
    "home.description.4": `Vitaspace没有传统的管理机关，所有决定都将使用区块链技术做出。每个去中心化国家的公民都将获得数字护照。 其数据将记录在区块链注册表中。`, // Vitacoin —— 去中心化国家的数字代币，交换手段和支付工具。
    "home.description.5": `每个Vitaspace成员都将能够为研发永葆青春，恢复健康和显著延缓衰老的技术做出贡献。Vitaspace公民的预期寿命是世界上最高的。`,

    "description.tab.coatOfArms": "徽标",
    "description.tab.flag": "旗帜",

    "description.tab.coatOfArms.content": `Vitaspace徽章用字母“ V”表示，該字母表示拉丁詞vita（表示“生命”）和無窮大的符號。 它們共同構成了永生的象徵。`,

    "description.tab.flag.content": `白色矩形，代表优雅、开放和知识。 旗帜中央是 Vitaspace  去中心化的享有治外法权的国家的徽章。`,

    "description.tab.vitapass.content.1": `Vitapass 是证实 Vitaspace 公民身份的主要规范的电子证件。获得VitaPass 需要完成一个简单的注册。输入下列信息至护照：`,
    "description.tab.vitapass.content.2": `电子护照的获得形式有两种，任选其一：金卡或铂金账户，是既定外观的塑料卡片。`,
    "description.tab.vitapass.content.li.names": "姓名",
    "description.tab.vitapass.content.li.date": "出生日期",
    "description.tab.vitapass.content.li.id": "识别号。",

    "google.recaptcha.terms": "本网站受reCAPTCHA保护",
    "google.recaptcha.terms.and": "和",
    "google.recaptcha.terms.policy": "隐私政策",
    "google.recaptcha.terms.terms": "服务条款",
    "google.recaptcha.terms.google": "Google适用于该网站。"
  }
};
