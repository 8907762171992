import styled from "styled-components";

export const FooterSection = styled.footer`
  background: #26303c;
  color: #d6b678;
  padding: 36px 0;
`;

export const SocIcons = styled.div`
  text-align: center;
  @media screen and (min-width: 576px) {
    text-align: left;
  }

  a {
    cursor: pointer;
    vertical-align: middle;

    &:not(:first-child) {
      margin-left: 8px;
    }

    @media screen and (min-width: 576px) {
      &:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
`;

export const ProjectIcons = styled.div`
  text-align: center;
  @media screen and (min-width: 576px) {
    text-align: right;
  }

  a {
    cursor: pointer;
    vertical-align: middle;

    &:not(:first-child) {
      margin-left: 8px;
    }

    @media screen and (min-width: 576px) {
      &:not(:first-child) {
        margin-left: 12px;
      }
    }

    img {
      vertical-align: middle;
    }
  }
`;

export const FooterLinks = styled.div`
  font-weight: 100;
  line-height: 1.4em;
  margin-top: 1.8em;
  padding-top: 1.8em;
  border-top: 1px solid rgba(214, 182, 120, 0.25);
  font-size: 12px;

  a {
    color: #d6b678;
    text-decoration: none;
    transition: 0.2s;
    border-bottom: 1px solid rgba(214, 182, 120, 0.4);
    opacity: 0.8;

    &:hover {
      border-bottom: 1px solid rgba(214, 182, 120, 0.7);
      opacity: 1;
    }
  }

  @media screen and (min-width: 768px) {
    ${"" /* margin-top: -36px; */}
  }
`;

export const FooterText = styled.div`
  ${({ align }) => align && `text-align: ${align}`}
  @media screen and (max-width: 576px) {
    text-align: center !important;
    line-height: 2em;
  }
`;
