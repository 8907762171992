import React from "react";

import { useTranslation } from "react-i18next";

import { Row, Col } from "react-flexbox-grid";

import { Head1, Grid, DescriptionBlock } from "./styled";

export default () => {
  const { t } = useTranslation();

  return (
    <DescriptionBlock>
      <Grid>
        <Row>
          <Col xs={12}>
            <Head1>VitaSpace</Head1>
            <p>{t("home.description.1")}</p>
            <p>{t("home.description.2")}</p>
            <p>{t("home.description.3")}</p>
            {/*<p>{t("home.description.4")}</p>*/}
            <p>{t("home.description.5")}</p>
          </Col>
        </Row>
      </Grid>
    </DescriptionBlock>
  );
};
