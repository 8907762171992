import styled from "styled-components";

export const ModalWrapper = styled.div`
  width: 100%;
  ${"" /* height: 100%; */}
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-content: stretch;

  @media screen and (min-width: 576px) {
    width: 480px;
    max-width: 480px;
    height: auto;
    min-height: auto;
  }
`;

export const ModalHeader = styled.div`
  min-width: 320px;
  background: #19212b;
  text-align: center;
  padding: 50px 16px 45px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;


  }
`;

export const ModalContent = styled.div`
  background: #26303c;
  padding: 16px;
  flex: 1;
  p {
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #616B74;
    span {
      color: #D2B47A;
    }

  }
`;

export const Button = styled.button`
  width: 100%;
  background: #d6b678;
  color: #26303c;
  border: 0;
  outline: none;
  padding: 22px;
  font-size: 18px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  text-align: center;
  transition: 0.5s;
`;

export const Link = styled.a`
width: 100%;
  background: #d6b678;
  color: #26303c;
  border: 0;
  outline: none;
  padding: 22px;
  font-size: 18px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  text-align: center;
  transition: 0.5s;
`

export const PriceWrapper = styled.div `
  text-align: center;
  margin-top: 20px
  span {
 
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #D2B47A;

  }
`

export const ErrorsList = styled.ul`
  font-size: 13px;
  color: #f44336;
  background-color: #2f1d1d;
  font-weight: 200;
  padding: 1em 1em 1em 2.2em;
  border-radius: 8px;
  border: 1px solid #612f31;
`;