import React, { PureComponent as Component } from "react";

import { withTranslation } from "react-i18next";
import { Row, Col } from "react-flexbox-grid";
import {
  Grid,
  DescriptionTabs,
  Tab,
  TabImage,
  TabDescription,
  TabDescriptionLeft,
  TabDescriptionRight,
  TabName,
  ButtonP,
  OrderButton
} from "./styled";

import emblem from "./emblem.svg";
import flag from "./flag.svg";
import card from "./card.svg";
import emblem01 from "./emblem.jpeg";
import flag01 from "./flag.jpg";
import card01 from "./vitapas_desktop.jpg";

const checkScrollTop = () => {
  document.body.style.top = `-${window.pageYOffset}px`;
};

class DescriptionTabsBlock extends Component {
  state = {
    activeTab: "card"
  };

  handleClickTab = tab => this.setState({ activeTab: tab });

  render() {
    const { t } = this.props;

    return (
      <>
        <DescriptionTabs>
          <Grid>
            <Row>
              <Col xs={4}>
                <Tab
                  onClick={() => this.handleClickTab("card")}
                  gold={
                    this.state.activeTab === "card" ||
                    this.state.activeTab.length === 0
                  }
                >
                  <TabImage>
                    <img src={card} alt="" />
                  </TabImage>
                  <TabName>VitaPass</TabName>
                </Tab>
              </Col>
              <Col xs={4}>
                <Tab
                  onClick={() => this.handleClickTab("emblem")}
                  gold={
                    this.state.activeTab === "emblem" ||
                    this.state.activeTab.length === 0
                  }
                >
                  <TabImage>
                    <img src={emblem} alt="" />
                  </TabImage>
                  <TabName>{t("description.tab.coatOfArms")}</TabName>
                </Tab>
              </Col>
              <Col xs={4}>
                <Tab
                  onClick={() => this.handleClickTab("flag")}
                  gold={
                    this.state.activeTab === "flag" ||
                    this.state.activeTab.length === 0
                  }
                >
                  <TabImage>
                    <img src={flag} alt="" />
                  </TabImage>
                  <TabName>{t("description.tab.flag")}</TabName>
                </Tab>
              </Col>
            </Row>
            <Row>
              <TabDescription
                unmountOnExit
                in={this.state.activeTab === "emblem"}
                timeout={300}
                exit={false}
              >
                <TabDescriptionLeft bgimage={emblem01} />
                <TabDescriptionRight>
                  <p>{t("description.tab.coatOfArms.content")}</p>
                </TabDescriptionRight>
              </TabDescription>

              <TabDescription
                unmountOnExit
                in={this.state.activeTab === "flag"}
                timeout={300}
                exit={false}
              >
                <TabDescriptionLeft bgimage={flag01} />
                <TabDescriptionRight>
                  <p>{t("description.tab.flag.content")}</p>
                </TabDescriptionRight>
              </TabDescription>

              <TabDescription
                unmountOnExit
                in={this.state.activeTab === "card"}
                timeout={300}
                exit={false}
              >
                <TabDescriptionLeft bgimage={card01} />
                <TabDescriptionRight>
                  <p>{t("description.tab.vitapass.content.1")}</p>
                  <ul>
                    <li>{t("description.tab.vitapass.content.li.names")}</li>
                    <li>{t("description.tab.vitapass.content.li.date")}</li>
                    <li>{t("description.tab.vitapass.content.li.id")}</li>
                  </ul>
                  <p>{t("description.tab.vitapass.content.2")}</p>
                  <ButtonP>
                    <OrderButton onClick={checkScrollTop} to="/order">
                      {t("orderVitapass")}
                    </OrderButton>
                  </ButtonP>
                </TabDescriptionRight>
              </TabDescription>
            </Row>
          </Grid>
        </DescriptionTabs>
      </>
    );
  }
}

export default withTranslation()(DescriptionTabsBlock);
