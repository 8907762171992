import styled from "styled-components";
import { Link } from "react-router-dom";
import { Grid as RFGrid } from "react-flexbox-grid";

export const Grid = styled(RFGrid)`
  @media only screen and (min-width: 768px) {
    width: 752px;
    max-width: 100%;
  }

  @media screen and (min-width: 992px) {
    width: 976px;
    max-width: 100%;
  }
`;

export const ModalWrapper = styled.div`
  width: 100%;

  height: 100%;
  display: flex;
  flex-flow: column;
  align-content: stretch;

  ${"" /* @media screen and (min-width: 480px) {
    width: 480px;
    max-width: 480px;
  } */}

  @media screen and (min-width: 576px) {
    width: 560px;
    max-width: 560px;
  }

  @media screen and (min-width: 768px) {
    width: 752px;
    max-width: 752px;
  }

  @media screen and (min-width: 992px) {
    width: 976px;
    max-width: 976px;
  }
  ${"" /*
  @media screen and (min-width: 1200px) {
    max-width: 1184px;
  } */}
`;

export const ModalContent = styled.div`
  background: #ffffff;
  padding: 32px 16px 32px 16px;
  color: #26303c;
  flex-grow: 1;
`;

export const OrderButton = styled.a`
  font-family: Roboto, sans-serif;
  padding: 12px 24px;
  background: #d6b677;
  color: #8a6110;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  margin-top: 32px;
`;

export const OrderWrapper = styled.div`
  text-align: center;
  margin-top: 32px;
`;

export const LogoutWrapper = styled.div`
  width: 100%;
  text-align: right;
`;

export const SettingsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const LkFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

export const Logout = styled(Link)`
  font-family: Roboto, sans-serif;
  color: #26303c;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  vertical-align: middle;

  span {
    vertical-align: middle;
  }

  img {
    height: 1.4em;
    vertical-align: middle;
    margin-left: 0.4em;
  }
`;

export const DescriptionP = styled.p`
  font-size: 13px;
  color: #808080;
  padding-top: 1em;
  text-align: left;

  a {
    color: #505050;
  }
`;

export const SystemMessage = styled.div`
  text-align: center;
`;
