import { userConstants } from "../constants";

export function user_data(state = {}, action) {
  switch (action.type) {
    case userConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETBYID_SUCCESS:
      return {
        data: action.user_data
      };
    case userConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case userConstants.PHOTO_CONSENT_REQUEST:
      return state;
    case userConstants.PHOTO_CONSENT_SUCCESS:
      if (state.data && state.data.profile) {
        const profileModified = {
          ...state.data.profile,
          show_in_map: action.consent
        };
        state.data.profile = profileModified;
      }
      return { ...state };

    case userConstants.PHOTO_CONSENT_FAILURE:
      return action.error;

    case userConstants.PHOTO_UPDATE_REQUEST:
      return {
        loading: true
      };
    case userConstants.PHOTO_UPDATE_SUCCESS:
      return {
        data: action.avatar,
        errors: false
      };

    case userConstants.PHOTO_UPDATE_FAILURE:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
}
