export default {
  headline: {
    ru: { text: "Часто задаваемые вопросы" },
    en: { text: "Frequently asked question" },
    es: { text: "Preguntas frecuentes" },
    cn: { text: "常见问题" }
  },
  ru: [
    {
      text: `Что такое Vitaspace?`,
      answer:
        "<p>Vitaspace — первое в мире децентрализованное экстерриториальное государство, где участники объединены общей идеей и задачей — продление молодости и активной жизни без болезней.</p>"
    },
    {
      text: `Почему было создано Vitaspace?`,
      answer: `<p>Большинство людей не задумывается о неизбежности конца. Вся их жизнь, как правило, построена вокруг материальных ценностей. По статистике страх смерти находится только на девятом месте. Это нелогично. Ведь единственное, что есть у человека — это его собственная жизнь, потеряв которую, он потеряет все.</p><p>Жизнь — самый главный и ценный ресурс, которым обладает человек. Мы хотим напомнить людям об этом. Но что важнее — мы хотим радикально продлить жизнь каждого человека. Однако справиться с такой задачей в одиночку практически невозможно, ведь это комплексный и сложный процесс. Поэтому мы создали Vitaspace.</p>`
    },
    {
      text: `Государства или частные компании не решают проблему продления жизни?`,
      answer: `<p>Все существующие системы не заинтересованы в радикальном продлении жизни человека. Иначе как объяснить тот факт, что на эту проблему тратится меньше 0,1% от всех средств, выделяемых на здравоохранение в мире?</p><p>Большинство религий и государств держатся на вере в конечность бытия. Смертными людьми проще управлять. Вместо продления жизни им предлагаются иллюзии — загробная жизнь, материальные и социальные блага и др.</p><p>С точки зрения бизнеса, лекарство, которое продлит жизнь и позволит не болеть, должно стоить очень дорого, чтобы компенсировать доход, потерянный от снижения продаж обычных средств. При этом клинические испытания такого препарата придется проводить довольно долго, а для компаний это слишком долгосрочное вложение. Поэтому, бюджеты ведущих фондов и фирм, инвестирующих в долголетие, не идут в сравнение с вложениями в препараты для лечения конкретных болезней, которые являются лишь следствием старения.</p>`
    },
    {
      text: `Разве ученые не могут решить эту проблему?`,
      answer: `<p>Чтобы исследования по борьбе со старением увенчались успехом, современная наука должна перестроиться. Сегодня она по-прежнему остается сильно бюрократизированной и консервативной структурой, а существующая грантовая система не стимулирует достижение конечного результата. В итоге исследования по продлению жизни остаются недофинансированными.</p><p>Наша цель — объединить неравнодушных людей для того, чтобы вместе победить старение и найти действенный способ существенно продлить активную жизнь человека. Совместными усилиями мы можем создать мощную научную структуру, не привязанную к государственным границам или отдельным личностям.</p>`
    },
    {
      text: `Вы планируете взаимодействовать с другими государствами?`,
      answer: `<p>Мы готовы сотрудничать с любыми структурами, которые поддерживают цель Vitaspace. Естественно, когда у нас будут совместные проекты по продлению жизни с теми или иными государствами, то мы будем согласовывать свои действия с их правительствами.</p>`
    },
    {
      text: `Почему был выбран формат децентрализованного экстерриториального государства? Как будет построена работа в Vitaspace?`,
      answer: `<p>Мы не хотим ограничивать себя географическими, национальными и иными категориями, разделяющими людей. Vitaspace не будет иметь классических органов управления, а все решения будут приниматься участниками сообщества при помощи блокчейн-технологий. Каждый гражданин децентрализованного государства Vitaspace получит цифровой паспорт. Все данные участников записываются в реестр блокчейна.</p>
      <p> Как и в любом государстве, в Vitaspace есть собственная ценность — Vitacoin — цифровой актив, с помощью которого каждый гражданин может участвовать в разработке средств по продлению жизни.</p>`
    },
    {
      text: `Как именно будут приниматься решения в Vitaspace?`,
      answer: `<p>В скором времени мы создадим собственную блокчейн платформу, которая позволит обеспечить прозрачность всех процедур в Vitaspace. Используя систему удаленного голосования участники проекта смогут определять направления его развития, оперативно решать возникшие проблемы. Каждый гражданин Vitaspace будет иметь возможность отслеживать все проводимые транзакции.</p>`
    },
    {
      text: `Планируете использовать собственную криптовалюту?`,
      answer: `<p>Нет. Правовой статус криптовалют значительно различается в разных странах и не до конца урегулирован, поэтому в данный момент времени не планируется их использовать.</p>`
    },
    {
      text: `Кто может стать гражданином Vitaspace?`,
      answer: `<p>Проект Vitaspace ориентируется на ответственных людей, которые верят в то, что границу старости и смерти можно отодвинуть во времени. Паспорт гражданина Vitaspace может получить любой, кто разделяет цели проекта, а также хочет активно участвовать в работе по радикальному продлению жизни.</p>`
    },
    {
      text: `Есть ли преимущества участия в Vitaspace?`,
      answer: `<p>Граждане Vitaspace смогут участвовать в разработке технологий, позволяющих сохранить молодость, вернуть здоровье и значительно отодвинуть старение. Когда будет создано лекарство, продлевающее жизнь, то они будут одними из первых, кто сможет им воспользоваться.</p>`
    },
    {
      text: `Кому принадлежит идея Vitaspace?`,
      answer: `<p>Идея создания Vitaspace принадлежит российскому инвестору и предпринимателю в сфере коммерческой недвижимости Андрею Фоменко. Уже более 20 лет он занимается темой продления жизни.
      В 1996 году Фоменко основал фонд «Вечная молодость», занимающийся финансированием и продвижением проектов, связанных с активным долголетием, а в декабре 2014 года создал компанию IVAO, которая специализируется на инвестициях в области биотехнологий.
      </p><p>Кроме того, Фоменко является членом экспертного совета Института исследований старения Бака, мирового лидера в области фундаментальных и прикладных исследований механизмов старения. Также он является сопредседателем организационного комитета международной научной конференции «Биомедицинские инновации для здорового долголетия».</p>`
    },
    {
      text: `Где находится штаб-квартира  Vitaspace?`,
      answer: `<p>Штаб-квартира Vitaspace базируется в Санкт-Петербурге (Россия), но наши участники работают по всему миру.</p>`
    }
  ],

  en: [
    {
      text: `What is Vitaspace?`,
      answer:
        "<p>Vitaspace is the world's first decentralized extraterritorial state, where members are united by a common idea and aim of the renewal of youth and active life without diseases.</p>"
    },
    {
      text: "Why was Vitaspace created?",
      answer: `<p>Most people do not think about the inevitability of the end. Their whole life is usually built around material values. According to statistics, the fear of death is only in ninth place. It's illogical. After all, life is the only thing a person has is his own life, losing which he will lose everything. </p>
    <p>Life is the most important and valuable resource that person has and we want to remind people of that. But more importantly, we want to radically extend the life of each person. However, to cope with this task alone is almost impossible, because it is a difficult and complex process. So, we created Vitaspace.</p>`
    },
    {
      text: `States or private companies do not solve the problem of prolonging life?
    `,
      answer:
        "<p>All existing systems are not interested in a radical extension of human life. Otherwise, how to explain the fact that only less than 0.1% of all funds allocated to health in the world is spent on this problem?</p><p>Most religions and states are based on belief that being is terminative. Deathlings are easier to control. Instead of prolonging life, they are offered with illusions, such as afterlife, material and social benefits, etc.</p><p>From a business point of view, a drug that will prolong life and allow not to get sick should be very expensive to compensate for the income lost from the decline in sales of conventional means. At the same time, clinical trials of such a drug will have to be carried out for a long time, and for companies it is the long-term investment, too. Therefore, the budgets of leading funds and firms investing in longevity is nothing compared to investments in drugs for the treatment of specific diseases that are only a consequence of aging.</p>"
    },
    {
      text: `Can't scientists solve this problem?`,
      answer: `
      <p>For anti-aging research to succeed, modern science must be rebuilt. Today, it remains a highly bureaucratic and conservative structure, and the existing grant system does not stimulate the achievement of the final result. As a result, life extension research remains underfunded.</p>
      <p>Our goal is to unite caring people in order to defeat aging together and to find an effective way to significantly extend the active life of a person. Together, we can create a powerful scientific structure that is not tied to state borders or individuals.</p>
    `
    },
    {
      text: `Do you plan to cooperate with other states?`,
      answer: `<p>We are ready to cooperate with any structures that support the Vitaspace goal. Of course, when we have joint projects to extend life with certain states, we will coordinate our actions with their governments.</p>`
    },
    {
      text: `Why was the format of a decentralized extraterritorial state chosen?<br>How will the work be organised in Vitaspace?`,
      answer: `<p>We do not want to limit ourselves to geographical, national and other criteria dividing people. Vitaspace will not have the classic authorities, and all decisions will be taken by the community members using blockchain technology. Every citizen of a decentralized state Vitaspace will receive a digital ID card. All data of participants is recorded in the blockchain registry.
    As any other state, Vitaspace has its own value — Vitacoin — digital asset through which every citizen can participate in the development of means to prolong life.</p>`
    },
    {
      text: `How exactly decisions will be made in Vitaspace?`,
      answer: `<p>Soon we will create our own blockchain platform that will provide transparency of all procedures in Vitaspace. Using the remote voting system, the project participants will be able to determine the direction of its development and to solve the problems quickly. Every citizen of Vitaspace will have the ability to track all ongoing transactions.</p>`
    },
    {
      text: `Are you planning to use your own cryptocurrency?`,
      answer: `<p>No. The legal status of cryptocurrencies varies significantly in different countries and is not fully regulated, so now we do not plan to use them.</p>`
    },
    {
      text: `Who can become a Vitaspace citizen?`,
      answer: `<p>Vitaspace project focuses on responsible people who believe that the border of old age and death can be postponed in time. Anyone who shares the objectives of the project and wants to be fully engaged in the work on radical life extension can receive Vitaspace ID card.</p>`
    },
    {
      text: `Are there any benefits of participating in Vitaspace?`,
      answer: `<p>Vitaspace citizens will be able to participate in the development of technologies to preserve youthfulness, restore health, and to significantly postpone aging. When a life-prolonging drug is created, they will at the forefront to use it.</p>`
    },
    {
      text: `Who owns the idea of Vitaspace?`,
      answer: `<p>The idea of creating Vitaspace belongs to the Russian investor and entrepreneur in the field of commercial real estate Andrey Fomenko. For more than 20 years he has been addressing the topic of life extension.
    In 1996 Fomenko founded the "Eternal youth" Fund, which is engaged in financing and promotion of projects related to active longevity, and in December 2014 he created the IVAO company that specializes in investments in the field of biotechnology.</p><p>n addition, Fomenko is a member of the Expert Council of the Buck Institute for aging research, a world leader in fundamental and applied research on aging mechanisms. He is also a co-chair of the organizing Committee of the international scientific conference "Biomedical innovations for healthy longevity".</p>`
    },
    {
      text: `Where is the Vitaspace headquarters?`,
      answer: `<p>Vitaspace headquarters is based in Saint-Petersburg (Russia), but our members work around the world.</p>`
    }
  ],

  es: [
    {
      text: `¿Qué es Vitaspace?`,
      answer:
        "<p>Vitaspace es el primer estado extraterritorial descentralizado del mundo, donde los participantes están unidos por una idea y tarea común de prolongar la juventud y la vida activa sin enfermedades.</p>"
    },
    {
      text: `¿Por qué se ha creado Vitaspace?`,
      answer: `<p>La mayoría de las personas no piensa en la inevitabilidad del fin.
       Toda su vida, como regla, se basa en los valores materiales. 
       Según las estadísticas, el miedo a la muerte se encuentra solo en el noveno lugar. 
       Esto es ilógico. 
       Después de todo, lo único que tiene una persona es su propia vida, al perder la cual, él perderá todo.</p>
       <p>
       La vida es el recurso principal y más valioso que posee una persona. 
       Queremos recordarselo a las personas. 
       Aún más importante es que queremos prolongar de forma radical la vida de cada persona. 
       Sin embargo, hacer frente a esta tarea solo es casi imposible, porque es un proceso complejo y complicado. 
       Por lo tanto, hemos creado Vitaspace.</p>`
    },
    {
      text: `¿El estado o las empresas privadas no solucionan el problema de prolongación de vida?`,
      answer: `
      <p>
      Todos los sistemas existentes no están interesados en la prolongación radical de la vida de la persona. 
      ¿Si no fuera así, como entonces se podría explicar el hecho de que en este problema se gasta menos de 0,1% de todos los fondos asignados a la asistencia sanitaria en el mundo?
      </p>
      <p>
      La mayoría de las religiones y estados se basan en la fe en la finitud del ser. 
      Es más fácil manejar a los hombres mortales. 
      En lugar de prolongar la vida se les proponen ilusiones - la ultratumba, beneficios materiales y sociales y etc.
      </p>
      <p>
        Desde el punto de vista del negocio, el medicamento que prolongará la vida y permitirá no tener enfermedades es muy costoso para compensar los ingresos perdidos por la reducción de las ventas de medicamentos convencionales. 
        Con todo esto, las pruebas clínicas de tal fármaco se tendrán que realizar durante largo tiempo, y para las empresas es una inversión demasiado de largo plazo. 
        Por lo tanto, los presupuestos de los principales fondos y empresas que invierten en la longevidad no se comparan con las inversiones en medicamentos para el tratamiento de enfermedades específicas que son solo una consecuencia del envejecimiento.
      </p>`
    },
    {
      text: `¿Acaso los científicos no pueden solucionar este problema?`,
      answer: `<p>Para que las investigaciones de lucha contra el envejecimiento tengan éxito, la ciencia moderna tiene que transformarse.
      Hoy en día, sigue siendo una estructura altamente burocrática y conservadora, y el sistema de donaciones existente no estimula el logro del resultado final. 
      Como resultado, los estudios de prolongación de la vida siguen sin financiamiento.</p>
      <p>
      Nuestro objetivo es unir a las personas no indiferentes para vencer juntos el envejecimiento y encontrar una manera efectiva de prolongar significativamente la vida activa de una persona. 
      Juntos, podemos crear una estructura científica poderosa que no esté ligada a las fronteras estatales ni a los individuos.
      </p>`
    },
    {
      text: `¿Planean Ustedes interactuar con otros estados?`,
      answer: `<p>
        Estamos preparados para cooperar con cualquier estructura que respalde el objetivo de Vitaspace. 
        Naturalmente, cuando tengamos proyectos para prolongar la vida en conjunto con varios estados, coordinaremos nuestras acciones con sus gobiernos.
        </p>`
    },
    {
      text: `¿Por qué ha sido elegido el formato de un estado extraterritorial descentralizado? ¿Cómo será organizado el trabajo en Vitaspace?`,
      answer: `<p>
        No queremos limitarnos a categorías geográficas, nacionales y de otro tipo que separan a las personas. 
        Vitaspace no va a tener organismos públicos clásicos, y todas las decisiones se tomarán por los participantes utilizando las tecnologías blockchain. 
        Cada ciudadano del estado descentralizado Vitaspace recibirá un pasaporte digital. 
        Todos los datos de los participantes se registran en el registro de blockchain.
        </p>
      <p> Como en cualquier estado, Vitaspace tiene su propio valor, Vitacoin, un activo digital con el que cada ciudadano puede participar en el desarrollo de herramientas para prolongar la vida.</p>`
    },
    {
      text: `¿De que forma precisamente se tomarán las decisiones en Vitaspace?`,
      answer: `<p>
        Pronto crearemos nuestra propia plataforma blockchain, que garantizará la transparencia de todos los procedimientos en Vitaspace. 
        Usando el sistema de votación remota, los participantes del proyecto podrán determinar la dirección de su desarrollo y resolver rápidamente los problemas que han surgido. 
        Cada ciudadano de Vitaspace podrá controlar todas las transacciones realizadas.</p>`
    },
    {
      text: `¿Planea Usted utilizar su propia criptomoneda?`,
      answer: `
        <p>
          No.
          El estado legal de las criptomonedas varía significativamente en diferentes países y 
          no está completamente regulado, por lo tanto, por el momento, no está previsto usarlas.
        </p>`
    },
    {
      text: `¿Quién puede hacerse ciudadano de Vitaspace?`,
      answer: `
      <p>
        El proyecto Vitaspace se centra en personas responsables que creen que la frontera de la vejez y la muerte puede ser aplazada en el tiempo. 
        Cualquiera que comparta los objetivos del proyecto y también quiera tomar una parte activa en el trabajo para prolongar de forma radical su vida puede recibir el pasaporte de ciudadano de Vitaspace.
        </p>`
    },
    {
      text: `¿Cuales son las ventajas de particpación en Vitaspace?`,
      answer: `<p>
        Los ciudadanos de Vitaspace podrán participar en el desarrollo de tecnologías para preservar la juventud, restaurar la salud y aplazar significativamente el envejecimiento.
        Cuando se cree un medicamento que prolongue la vida, serán uno de los primeros en usarlo.
        </p>`
    },
    {
      text: `¿A quién le pertenece la idea de Vitaspace?`,
      answer: `
        <p>
          La idea de la creación de Vitaspace pertenece al inversor y empresario ruso en el campo de bienes inmuebles Andrey Fomenko. 
          Lleva 20 años trabajando con el tema de prolongación de la vida.
          En 1996, Fomenko fundó el fondo "Juventud eterna" que financia y promueve proyectos relacionados con la longevidad activa, y en diciembre de 2014 creó la empresa IVAO que se especializa en inversiones en el campo de biotecnologías.
        </p>
        <p>
          Además, Fomenko es miembro del consejo de expertos del Instituto de investigación de Envejecimiento, líder mundial en el campo de la investigación básica y aplicada de mecanismos de envejecimiento. 
          También es el copresidente del comité organizador de la conferencia científica internacional "Innovación biomédica para una longevidad saludable".
        </p>`
    },
    {
      text: `¿Dónde se encuentra la sede de Vitaspace?`,
      answer: `<p>La sede de Vitaspace se encuentra en San Petersburgo (Rusia), pero nuestros participantes trabajan por todo el mundo.</p>`
    }
  ],

  cn: [
    {
      text: `什么是Vitaspace？`,
      answer:
        "<p>Vitaspace是世界上第一个权力下放的域外国家，参与者在一个共同的思想和任务下团结一致，即永葆青春和无疾病的积极生活。</p>"
    },
    {
      text: `为什么创建Vitaspace？`,
      answer: `<p>大多数人没有想到终结的必然性。 通常，他们的整个生活都是围绕物质价值而建立的。据统计，对死亡的恐惧仅排在第九位。 这是不合逻辑的。 毕竟，一个人唯一的生活就是自己的生活，失去生命，他将失去一切。</p><p>生命是一个人拥有的最重要和最宝贵的资源。 我们想提醒人们这一点。 但更重要的是，我们希望从根本上延长每个人的寿命。 但是，仅要应付这样的任务几乎是不可能的，因为这是一个复杂的过程。 因此，我们创建了Vitaspace。</p>`
    },
    {
      text: `国家或私营公司是否不能解决延长寿命的问题？`,
      answer: `<p>现有的所有系统都不希望对人类生命的彻底扩展产生兴趣。 否则，我们如何解释这一问题呢？世界上分配给医疗保健的资金不到0.1％？</p>
              <p>大多数宗教和国家在存在的有限性上都依赖信仰。 凡人更容易控制。 
              他们没有延长寿命，反而给他们带来了幻觉-来世，物质和社会福利等。</p>
              <p>从业务的角度来看，延长寿命并且不让您生病的药物必须非常昂贵，才能弥补因常规药物销售下降而造成的收入损失。 
              同时，这种药物的临床试验将必须进行相当长的时间，对于公司而言，这是一项长期投资。 
              因此，领先资金和投资于长寿的公司的预算不能与仅用于治疗衰老的特定疾病的药物投资相提并论。</p>`
    },
    {
      text: `科学家不能解决这个问题吗？`,
      answer: `<p>为了成功进行抗衰老研究，必须重建现代科学。 
      今天，它仍然是一个高度官僚和保守的结构，现有的赠款制度并不能促进最终结果的实现。 
      结果，延长寿命的研究仍然资金不足。
      </p>
      <p>我们的目标是团结有爱心的人们，共同克服衰老，并找到有效途径来显着延长人们的活跃寿命。 
      在一起，我们可以创建一个不受国家边界或个人束缚的强大科学结构。
      </p>`
    },
    {
      text: `您打算与其他州进行互动吗？`,
      answer: `<p>我们准备与支持Vitaspace目标的任何机构合作。 
      自然，当我们有共同的项目来延长与各州的寿命时，我们将与他们的政府协调行动。</p>`
    },
    {
      text: `为什么选择分散的域外国家形式？ 如何在Vitaspace中构建作品？`,
      answer: `<p>我们不想将自己局限于地域，民族和其他将人分开的类别。 
      Vitaspace将没有经典的控件，所有决定将由社区成员使用区块链技术做出。 
      每个分权州的公民都将获得数字护照。 所有参与者数据都记录在区块链注册表中。</p>
      <p>就像在任何州一样，Vitaspace都有自己的价值-Vitacoin-一种数字资产，每个公民都可以使用它来开发寿命延长工具。</p>`
    },
    {
      text: `在Vitaspace中将如何准确地做出决策？`,
      answer: `<p>
      在不久的将来，我们将创建自己的区块链平台，以确保Vitaspace中所有程序的透明性。 
      使用远程投票系统，项目参与者将能够确定其发展方向，并迅速解决出现的问题。 
      每个Vitaspace公民都将能够跟踪所有正在进行的交易。
      </p>`
    },
    {
      text: `计划使用您自己的加密货币？`,
      answer: `<p>不行. 加密货币的法律地位在不同国家/地区存在很大差异，并且尚未得到充分监管，因此，目前尚不打算使用它们。</p>`
    },
    {
      text: `谁可以成为Vitaspace公民？`,
      answer: `<p>
      Vitaspace项目的重点是负责任的人，他们相信老年人和死亡的边界可以及时回溯。 
      谁拥有该项目的目标，并且也想积极参与到彻底延长寿命的工作中，谁就能获得Vitaspace护照。</p>`
    },
    {
      text: `加入Vitaspace有什么好处？`,
      answer: `<p>
      Vitaspace的公民将能够参与技术开发，以保护年轻人，恢复健康并显着延缓衰老。
      当创造出可以延长寿命的药物时，他们将是最早使用该药物的人之一。
      </p>`
    },
    {
      text: `谁拥有Vitaspace创意？`,
      answer: `<p>
      创建Vitaspace的想法属于商业房地产领域的俄罗斯投资者和企业家Andrei Fomenko。 
      20多年来，他一直致力于延长寿命这一主题。
      福门科在1996年成立了“永恒青年基金会”，该基金会为与积极长寿有关的项目提供资金并促进其发展，并于2014年12月成立了IVAO公司，该公司专门从事生物技术领域的投资。
      </p>
      <p>
      此外，福缅科是巴克衰老研究所的专家委员会成员，该研究所是衰老机制的基础和应用研究的世界领导者。 
      他还是国际科学大会“健康长寿的生物医学创新”组织委员会的联席主席。</p>`
    },
    {
      text: `Vitaspace总部在哪里？`,
      answer: `<p>Vitaspace的总部位于圣彼得堡（俄罗斯），但我们的成员在全球范围内工作。</p>`
    }
  ]
};
