import Faq from "../components/Faq";

import React, { PureComponent as Component } from "react";

import { ScrollToHOC } from "react-scroll-to";

import ym from "react-yandex-metrika";

class FaqPage extends Component {
  componentDidMount() {
    const { scrollToContent } = this.props.location;
    if (scrollToContent) {
      this.handleScrollToContent();
    }

    ym("hit", "/faq");
  }

  componentDidUpdate() {
    const { scrollToContent } = this.props.location;
    if (scrollToContent) {
      this.handleScrollToContent();
    }
  }

  handleScrollToContent = () => {
    const { scrollTo } = this.props;
    const windowHeight = window.innerHeight;
    scrollTo({ y: windowHeight, smooth: true });
    const windowWidth = window.screen.innerWidth;
    if (windowWidth < 768) {
      scrollTo({ y: windowHeight - 80, smooth: true });
    } else {
      scrollTo({ y: windowHeight, smooth: true });
    }
  };

  render() {
    return <Faq />;
  }
}

export default ScrollToHOC(FaqPage);
