import React, { PureComponent as Component } from "react";
// import cover from "./cover.jpeg";
import { HomeVideoWrapper } from "./styled";

// const sourceVideo = "https://www.youtube.com/embed/fubClGYl0Xs";

class HomeVideo extends Component {
  render() {
    return (
      <HomeVideoWrapper>
        {/* <video
          style={{ outline: "none" }}
          width="100%"
          src={sourceVideo}
          controls="controls"
          poster={cover}
        ></video> */}

        <iframe
          title="YouTube Video"
          width="100%"
          height="500px"
          src="https://www.youtube.com/embed/fubClGYl0Xs"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </HomeVideoWrapper>
    );
  }
}

export default HomeVideo;
