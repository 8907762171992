import React, { PureComponent as Component } from "react";
// import onClickOutside from "react-onclickoutside";
import ReactHtmlParser from "react-html-parser";
import { ArticleWrapper, ArticleContentWrapper, ArticleText, ArticleAnswer, ArticleDateWrapper } from "./styled";

const initialState = { active: false };

class Article extends Component {
  state = initialState;

  handleClick = () =>
    this.setState(prevState => ({
      active: !prevState.active
    }));

  componentWillMount() {
    this.props.active === true &&
      this.setState(prevState => ({
        active: true
      }));
  }

  // handleClickOutside = event =>
  //   this.setState({
  //     active: false
  //   });

  render() {
    const { title, text, date } = this.props;
    console.log(this.props);
    return (
      <ArticleWrapper>
        <ArticleDateWrapper>{date || 'N/A'}</ArticleDateWrapper>
        <ArticleContentWrapper>
          <ArticleText onClick={this.handleClick} active={this.state.active}>
            {ReactHtmlParser(title)}
          </ArticleText>
          {this.state.active && (
            <ArticleAnswer>{ReactHtmlParser(text)}</ArticleAnswer>
          )}
        </ArticleContentWrapper>
      </ArticleWrapper>
    );
  }
}

// export default onClickOutside(Article);
export default Article;
