import styled from "styled-components";

export const Spinner = styled.span`
  width: ${({ big }) => (big ? "32px" : "16px")};
  height: ${({ big }) => (big ? "32px" : "16px")};
  margin-right: 4px;
  border-radius: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 2px solid transparent;
    border-top-color: ${({ light }) => (light ? "#aeb0b3" : "#f3c264")};
  }
  &:before {
    z-index: 100;
    animation: spin 1s infinite;
  }
  &:after {
    border: 2px solid ${({ light }) => (light ? "#cecece" : "#40454c")};
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
