/* eslint-disable react/jsx-no-target-blank */
import React, { PureComponent as Component } from "react";
import { Link } from "react-router-dom";
import LanguageMenu from "../LanguageMenu";
import { languages } from "../../languages/languages";

import { connect } from "react-redux";

import { withTranslation } from "react-i18next";

import { Grid, Row, Col } from "react-flexbox-grid";
import CheeseburgerMenu from "cheeseburger-menu";
import HamburgerMenu from "react-hamburger-menu";

import {
  HeaderBlock,
  HeaderMenu,
  Burger,
  BurgerMenu
} from "./styled";

import logo from "./logo.svg"

class Header extends Component {
  state = {
    menuOpen: false
  };

  openMenu = () => {
    this.setState({ menuOpen: true });
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  changeLanguage = lng => {
    this.props.i18n.changeLanguage(lng);
  };

  getCurrentLang = () => {
    const i18nextLng = localStorage.getItem("i18nextLng");
    return languages.find(lang => i18nextLng === lang.code);
  };

  render() {
    const { absolute, invert, t, user } = this.props;
    const current_lang = this.getCurrentLang();

    // const { t, i18n } = useTranslation();

    return (
      <>
        <CheeseburgerMenu
          backgroundColor="#19212b"
          right={true}
          isOpen={this.state.menuOpen}
          closeCallback={this.closeMenu}
        >
          <BurgerMenu>
            <li>
              <Link
                onClick={this.closeMenu}
                to={{ pathname: "/", scrollToContent: true }}
              >
                {t("header.about")}
              </Link>
            </li>
            <li>
              <Link
                onClick={this.closeMenu}
                to={{ pathname: "/manifest", scrollToContent: true }}
              >
                {t("header.manifest")}
              </Link>
            </li>
            <li>
              <Link
                onClick={this.closeMenu}
                to={{ pathname: "/faq", scrollToContent: true }}
              >
                {t("header.faq")}
              </Link>
            </li>
            <li>
              <a href="https://lectures.vitaspace.net/" target="_blank">
                {t("header.lectures")}
              </a>
            </li>
            <li>
              <a href="https://t.me/vita_space">
                {t("header.forum")}
              </a>
            </li>
            <li>
              <Link
                onClick={this.closeMenu}
                to={{ pathname: "/news", scrollToContent: true }}
              >
                {t("header.news")}
              </Link>
            </li>
            {user && user.id ? (
              <li>
                <Link onClick={this.closeMenu} to="/settings/photo">
                  {t("header.account")}
                </Link>
              </li>
            ) : (
              <>
                <li>
                  <Link onClick={this.closeMenu} to="/login">
                    {t("auth.sign.in")}
                  </Link>
                </li>
                <li>
                  <Link onClick={this.closeMenu} to="/register">
                    {t("auth.sign.up")}
                  </Link>
                </li>
              </>
            )}
          </BurgerMenu>
        </CheeseburgerMenu>
        <HeaderBlock absolute={absolute} invert={invert}>
          <Grid>
            <Row middle="xs">
              <Col xs={1}>
                  <img styled={{"margin-left": "80px"}} src={logo} />
              </Col>
              <Col xs={1}/>
              <Col xs={9}>
                <Burger>
                  <HamburgerMenu
                    isOpen={this.state.menuOpen}
                    menuClicked={this.openMenu}
                    width={24}
                    height={16}
                    strokeWidth={2}
                    rotate={0}
                    color="#d6b678"
                    borderRadius={0}
                    animationDuration={0.5}
                  />
                </Burger>
                <HeaderMenu right>
                  <li>
                    <Link to={{ pathname: "/", scrollToContent: true }}>
                      {t("header.about")}
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/manifest", scrollToContent: true }}>
                      {t("header.manifest")}
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/faq", scrollToContent: true }}>
                      {t("header.faq")}
                    </Link>
                  </li>
                  <li>
                    <a href="https://lectures.vitaspace.net/" target="_blank">
                      {t("header.lectures")}
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/vita_space">
                      {t("header.forum")}
                    </a>
                  </li>
                  <li>
                    <Link to={{ pathname: "/news", scrollToContent: true }}>
                      {t("header.news")}
                    </Link>
                  </li>
                  {user && user.id ? (
                    <li>
                      <Link to="/settings/photo">{t("header.account")}</Link>
                    </li>
                  ) : (
                    <>
                      <li>
                        <Link to="/login">{t("auth.sign.in")}</Link>
                      </li>
                      <li className="screen-1440">
                        <Link to="/register">{t("auth.sign.up")}</Link>
                      </li>
                    </>
                  )}
                  {/* <li>
                  <Link to="/account">{t("header.account")}</Link>
                </li> */}
                </HeaderMenu>
              </Col>
              <Col xs={1}>
                <LanguageMenu
                  languages={languages}
                  current_lang={current_lang}
                  changeLanguage={this.changeLanguage}
                />
              </Col>
            </Row>
          </Grid>
        </HeaderBlock>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { registration, authentication } = state;
  const { user } = registration.user ? registration : authentication;
  return {
    user
  };
}

const connectedHeader = connect(mapStateToProps)(Header);

export default withTranslation()(connectedHeader);
