import React, { PureComponent as Component } from "react";
import * as THREE from "three";
import ForceGraph3D from "3d-force-graph";
import { connect } from "react-redux";
import { languages } from "../../languages/languages";
import { withTranslation } from "react-i18next";

import qntPeopleData from "./qntPeopleData";

class UsersGraph extends Component {
  constructor(props) {
    super(props);
    const getAmountOfUsers = async () => {
      const response = await fetch(
        "https://api.vitaspace.net/api/users/user-graph/"
      );
      const myJson = await response.json();
      this.setState({ amount_of_users: myJson.amount_of_users });
      return myJson;
    };
    getAmountOfUsers();
  }

  state = { amount_of_users: 1173 };

  componentDidMount() {
    // var loader = new THREE.SVGLoader();

    const elem = document.getElementById("3d-graph");

    const Graph = ForceGraph3D({
      antialias: true,
      alpha: false
    })(elem)
      .jsonUrl("https://api.vitaspace.net/api/users/user-graph/")
      .nodeAutoColorBy("user")
      .height(500)
      .nodeThreeObject(node => {
        const obj = new THREE.Mesh(
          new THREE.SphereGeometry(12),
          new THREE.MeshBasicMaterial({
            depthWrite: false,
            transparent: true,
            opacity: 0
          })
        );
        // add img sprite as child

        const imgTexture = new THREE.TextureLoader().load(
          node.url
            ? `https://api.vitaspace.net${node.url}`
            : "https://api.vitaspace.net/media/user.svg"
        );
        const material = new THREE.SpriteMaterial({
          map: imgTexture
        });
        const sprite = new THREE.Sprite(material);
        sprite.scale.set(12, 12);
        obj.add(sprite);

        return obj;
      })
      .backgroundColor("rgb(38, 48, 60)")
      .linkColor("#000000")
      .linkOpacity(1)
      .linkWidth(0.2)
      .linkDirectionalParticleColor("red");
  }
  getCurrentLang = () => {
    const i18nextLng = localStorage.getItem("i18nextLng");
    return languages.find(lang => i18nextLng === lang.code);
  };

  render() {
    const { t } = this.props;
    const currentLang = this.getCurrentLang();
    let currentLangCode = currentLang.code;

    if (!["ru", "en"].includes(currentLangCode)) {
      currentLangCode = "en";
    }

    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-offset-2 col-xs-8">
              <h3>
                {qntPeopleData.header[currentLangCode].text}:{" "}
                {this.state.amount_of_users}
              </h3>
            </div>
          </div>
        </div>
        <div id="3d-graph"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registration, authentication } = state;
  const { user } = registration.user ? registration : authentication;
  return {
    user
  };
}

const graphUsers = connect(mapStateToProps)(UsersGraph);
export default withTranslation()(graphUsers);
