import React, { PureComponent as Component } from "react";
import onClickOutside from "react-onclickoutside";
import {
  CurrentLang,
  Dropdown,
  DropdownItem,
  CurrentLangWrapper,
  Flag
} from "./styled";

class LanguageMenu extends Component {
  state = {
    shown: false
  };

  handleClick = () =>
    this.setState(prevState => ({
      shown: !prevState.shown
    }));

  handleClickOutside = event =>
    this.setState({
      shown: false
    });

  render() {
    const { current_lang, changeLanguage } = this.props;

    return (
      <CurrentLangWrapper onClick={this.handleClick}>
        {current_lang && (
          <CurrentLang>
            <Flag src={current_lang.image} alt={current_lang.name} />
            <span>{current_lang.name}</span>
          </CurrentLang>
        )}

        {this.state.shown && (
          <Dropdown>
            {this.props.languages.map(lang => (
              <DropdownItem
                active={current_lang.code === lang.code}
                key={lang.code}
                onClick={() => changeLanguage(lang.code)}
              >
                <Flag src={lang.image} alt={lang.name} />
                <span>{lang.name}</span>
              </DropdownItem>
            ))}
          </Dropdown>
        )}
      </CurrentLangWrapper>
    );
  }
}

export default onClickOutside(LanguageMenu);
