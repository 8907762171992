import styled from "styled-components";

export const CurrentLangWrapper = styled.div`
  position: relative;
`;

export const CurrentLang = styled.div`
  display: inline-block;
  cursor: pointer;
  position: relative;
  user-select: none;
  padding: 8px 12px;
  color: #d6b678;
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  img {
    transition: 0.1s ease-out;
    opacity: 0.8;
  }

  &:hover {
    img {
      opacity: 1;
    }
    span {
      text-decoration: underline;
    }
  }
`;

export const Dropdown = styled.div`
  top: 0;
  position: absolute;
  user-select: none;
  background: #19212b;
  border-radius: 4px;
  overflow: hidden;
`;

export const DropdownItem = styled.div`
  user-select: none;
  padding: 8px 12px;
  cursor: pointer;
  background: rgba(16, 26, 33, 0);
  transition: 0.1s ease-out;
  color: #d6b678;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    transition: 0.1s ease-out;
    opacity: 0.8;
  }

  &:hover {
    img {
      opacity: 1;
    }

    background: rgba(19, 26, 33, 1);
  }

  ${({ active }) =>
    active && `background: rgba(22, 29, 37, 1); color: #ffffff;`}
`;

export const Flag = styled.img`
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 0.4em;
`;
