import React, { PureComponent as Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { withTranslation } from "react-i18next";
import { languages } from "../../languages/languages";
import Question from "./Question";
import { FaqBlock, Grid } from "./styled";

import questionsData from "./questionsData";

class Faq extends Component {
  getCurrentLang = () => {
    const i18nextLng = localStorage.getItem("i18nextLng");
    return languages.find(lang => i18nextLng === lang.code);
  };

  render() {
    const currentLang = this.getCurrentLang();
    let currentLangCode = currentLang.code;
    if (!["ru", "en", "es", "cn"].includes(currentLangCode)) {
      currentLangCode = "en";
    }
    return (
      <FaqBlock>
        <Grid>
          <Row>
            <Col xs={12}>
              <h1>{questionsData.headline[currentLangCode].text}</h1>
              {currentLangCode &&
                questionsData[currentLangCode].length > 0 &&
                questionsData[currentLangCode].map((question, i) => (
                  <Question
                    key={i}
                    text={question.text}
                    answer={question.answer}
                    active={i === 0}
                  />
                ))}
            </Col>
          </Row>
        </Grid>
      </FaqBlock>
    );
  }
}

export default withTranslation()(Faq);
