import uk_flag from "./uk.svg";
import russia_flag from "./russia.svg";
import spain_flag from "./spain.svg";
import china_flag from "./china.svg";

export const languages = [
  { code: "en", name: "English", image: uk_flag },
  { code: "ru", name: "Русский", image: russia_flag },
  { code: "es", name: "Español", image: spain_flag },
  { code: "cn", name: "中国", image: china_flag }
];
