import styled from "styled-components";
import transition from "styled-transition-group";
import { Grid as RFGrid } from "react-flexbox-grid";
import { Link } from "react-router-dom";

export const DescriptionBlock = styled.section`
  color: #26303c;
  padding: 0 16px;

  p {
    font-size: 18px;
    line-height: 1.4em;
    font-weight: 300;
    ${"" /* text-align: justify; */}

    span {
      font-style: italic;
    }
  }
`;

export const Head1 = styled.h1`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 40px;
  border-bottom: 4px solid #d6b678;
  padding-bottom: 12px;
  margin-top: 36px;
  margin-bottom: 24px;
  width: 100%;
`;

export const Head2 = styled.h2`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 40px;
  border-bottom: 4px solid #d6b678;
  padding-bottom: 12px;
  margin-top: 36px;
  margin-bottom: 24px;
  width: 100%;
`;

export const Grid = styled(RFGrid)`
  @media only screen and (min-width: 768px) {
    width: 752px !important;
    max-width: 100%;
  }
`;

export const DescriptionTabs = styled.section`
  padding: 36px 0;
`;

export const Tab = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24px;
  opacity: 0.8;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  filter: ${props => (props.gold ? "none" : "grayscale(100%)")};

  &:hover {
    cursor: pointer;
    opacity: 1;
    transform: scale(1.05);
    filter: none;
  }
`;

export const TabImage = styled.div`
  img {
    width: 64px;
    height: 64px;

    @media screen and (min-width: 576px) {
      width: 125px;
      height: 125px;
    }
  }
`;

export const TabName = styled.div`
  padding-top: 4px;
  font-size: 12px;
  white-space: nowrap;

  @media screen and (min-width: 576px) {
    font-size: 20px;
  }
`;

export const TabDescription = transition.div`
  line-height: 1.4em;
  border-top: 4px solid #d6b678;
  border-bottom: 4px solid #d6b678;
  
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 576px) {
    flex-direction: row;
  }

  &:enter {
    opacity: 0;
  }
  &:enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  &:exit {
    opacity: 1;
  }
  &:exit-active {
    transition: opacity 300ms;
  }
  
`;

export const TabDescriptionLeft = styled.div`
  ${props =>
    !!props.bgimage && props.bgimage.length > 0
      ? `background-image: url(${props.bgimage});`
      : ""}
  width: 100%;
  background-position: center center;
  background-size: cover;
  min-height: 380px;

  @media screen and (min-width: 576px) {
    width: 50%;
  }
`;

export const TabDescriptionRight = styled.div`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;

  p,
  ul {
    font-weight: 300;
  }

  @media screen and (min-width: 576px) {
    width: 50%;
    padding-left: 32px;
  }
`;

export const ButtonP = styled.p`
  text-align: center;
`;

export const OrderButton = styled(Link)`
  font-family: Roboto, sans-serif;
  padding: 12px 24px;
  background: #d6b677;
  color: #8a6110;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
`;
