import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { user_data } from "./user_data.reducer";
import { check_password } from "./check_password.reducer";
import { change_password } from "./change_password.reducer";
import { forgot_password } from "./forgot_password.reducer";
import {order} from './order.reducer'

const rootReducer = combineReducers({
  authentication,
  registration,
  user_data,
  check_password,
  change_password,
  forgot_password,
  order
});

export default rootReducer;
