import { userConstants } from "../constants";

export function forgot_password(state = {}, action) {
  switch (action.type) {
    case userConstants.FORGOT_PASSWORD_REQUEST:
      return { request: true };
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return { success: action.forgot };
    case userConstants.FORGOT_PASSWORD_FAILURE:
      return { errors: action.errors };
    default:
      return state;
  }
}
