import styled from "styled-components";
import { Link } from "react-router-dom";

export const Burger = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const BurgerMenu = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding-top: 16px;

  li {
    a {
      display: block;
      padding: 16px 24px;
      font-size: 18px;
      text-decoration: none;
      color: #d6b678;
    }
  }
`;

export const HeaderBlock = styled.header`
  padding: 24px 16px 24px 4px;
  ${"" /* position: ${props =>
    props.absolute ? "absolute" : props.fixed ? "fixed" : "inherit"}; */}
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  color: ${props => (props.invert ? "#FFFFFF" : "#26303c")};
  

  @media screen and (max-width: 1024px) {
    background-color: rgba(38, 48, 60, 0.6);
  }

  @media screen and (min-width: 1024px) {
    padding: 0 16px 0 4px;
    position: absolute;
  }

  a {
    color: ${props => (props.invert ? "#26303c" : "#d6b678")};
  }
`;

export const HeaderMenu = styled.ul`
  padding: 28px 0;
  margin: 0;
  list-style-type: none;
  ${props => (props.right ? "text-align: right" : "")};
  ${props => (props.inline ? "display: inline-block" : "")};
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }

  &:not(:first-child) {
    margin-left: 2px;
  }

  .screen-1440{
      @media screen and (max-width: 1600px) {
        display: none;
      }
    }

  .screen-1024{
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

  li {
    display: inline;
    color: #d6b678;
    transition: 0.2s;

    &:not(:first-child) {
      margin-left: 6px;
      @media screen and (min-width: 576px) {
        margin-left: 12px;
      }

      &::before {
        content: " ";
        text-decoration: none;
        color: #808080;
        margin-right: 6px;

        @media screen and (min-width: 576px) {
          margin-right: 12px;
        }
      }
    }

    img {
      vertical-align: middle;

      height: 32px;

      @media screen and (min-width: 576px) {
        margin-right: 10px;
      }
    }
  }

  a,
  button {
    padding: 0;
    color: #d6b678;
    text-decoration: none;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      &::before {
        text-decoration: none;
      }
    }
  }
`;

export const VitacoinSpan = styled.span`
  display: none;
  @media screen and (min-width: 576px) {
    display: inline;
  }
`;

export const LangButton = styled.button`
  color: #d6b678;
  text-decoration: none;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    &::before {
      text-decoration: none;
    }
  }

  ${props => (props.active ? "text-decoration:underline;color: #FFFFFF;" : "")}
`;
