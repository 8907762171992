import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import resources from "./i18n_resources";

// const getLanguage = () => {
//   const saved_lang = localStorage.getItem();
// }

const getSetLang = () => {
  const langs = Object.keys(resources);
  let lang = localStorage.getItem("i18nextLng");
  if (!!lang && lang.length > 0) {
    lang = lang.substr(0, 2);
  }

  if (langs.includes(lang)) {
    localStorage.setItem("i18nextLng", lang);
  } else {
    localStorage.setItem("i18nextLng", "en");
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",

    keySeparator: false,

    interpolation: {
      escapeValue: true
    }
  });

getSetLang();

export default i18n;
