export default {
  translation: {
    "header.account": "Личный кабинет",
    "header.faq": "FAQ",
    "header.about": "О проекте",
    "header.lectures": "Лекции",
    "header.news": "Новости проекта",
    "header.forum": "Обсуждение",
    "header.manifest": "Декларация",

    founding_date: "Дата основания государства",
    "description": "Децентрализованное экстерриториальное государство, которое дает возможность получить право на жизнь, радикально увеличенную, активную и здоровую",
    "join": "Вступить",

    "footer.rights": "Все права защищены",
    "footer.policy": "Политика обработки персональных данных",
    "footer.agreement": "Пользовательское соглашение",

    "auth.sign.in": "Вход",
    "auth.sign.up": "Регистрация",
    "auth.sign.out": "Выход",
    "auth.email": "Email",
    "auth.name": "Имя",
    "auth.surname": "Фамилия",
    "auth.phone": "Телефон",
    "auth.password": "Пароль",
    "auth.forgot": "Забыли пароль",
    "auth.forgot.disclaimer":
      "Введите свой адрес электронной почты, чтобы получить новый пароль.",
    "auth.forgot.recover": "Восстановить пароль",
    "auth.recovery": "Восстановление пароля",

    "settings.photo": "Фотография",
    "settings.security": "Безопасность",
    "settings.share": "Показывать мою фотографию на общей карте",
    "settings.your_photo": "Ваша фотография",
    "settings.upload_photo": "Загрузить фото",
    "settings.update_photo": "Обновить фото",
    "settings.visibility": "Видимость фотографии",

    "registration.policy": `Подтверждая регистрацию, вы соглашаетесь на обработку своих персональных данных, а также с условиями пользовательского соглашения.`,
    "registration.policy_full": `Подтверждая регистрацию, вы соглашаетесь на <2>обработку своих персональных данных</2>, а также с условиями <6>пользовательского соглашения</6>.`,

    upload: "Загрузить",
    loading: "Загрузка",
    error: "Error",

    orderVitapass: "Заказать физический VitaPass",
    readMore: "Читать далее",

    "account.welcome": "Добро пожаловать",
    "account.changeCaption": `Чтобы внести изменения в профиль VitaPass (загрузить новую
        фотографию, изменить фамилию и имя), отправьте
        соответствующий запрос по электронной почте`,
    "account.close": "Закрыть",

    "home.description.1": `Vitaspace — первое в мире децентрализованное экстерриториальное государство, где участники объединены общей идеей — продление молодости и активной жизни без болезней.`,
    "home.description.2": `Многие люди считают себя гражданами мира. Мы также предлагаем не ограничиваться географическими, национальными и иными категориями, разделяющими людей.`,
    "home.description.3": `Vitaspace даст возможность переписать правила, управляющие обществом. Все вместе граждане децентрализованного государства объединят свои усилия для радикального продления жизни.`,
    "home.description.4": `Vitaspace не имеет классических органов управления, а все решения будут приниматься при помощи блокчейн-технологий. Каждый гражданин децентрализованного государства получит цифровой паспорт. Его данные будут записаны в реестр блокчейна.`, // Vitacoin - цифровой токен децентрализованного государства, мера обмена и средство платежа.
    "home.description.5": `Каждый участник Vitaspace сможет внести вклад в разработку технологий, позволяющих сохранить молодость, вернуть здоровье и значительно отодвинуть старение. Ожидается, что продолжительность жизни граждан Vitaspace будет самой высокой в мире.`,
    
    "home.manifest.headline": `Декларация по проблемам радикального продления жизни`,
    "home.manifest.text.1":  `В системе международно-правовых стандартов право на жизнь является фундаментальной ценностью человека. 
    Мы, граждане и основатели децентрализованного экстерриториального государства <a href="/">Vitaspace</a>, 
    а также участники проекта <a href="https://vitacoin.net/ru/" target="_blank">Vitacoin</a>, считаем, что старение и смерть явно нарушают это право, 
    и призываем сплотиться всех людей вокруг идеи радикального продления жизни.`,
    "home.manifest.text.2": `<span>Принимая во внимание</span>, что население во всем мире беспрецедентно стареет и умирает,`,
    "home.manifest.text.3": `<span>принимая во внимание также</span>, 
    что абсолютно все люди и государства должны объединиться в 
    борьбе против двух врагов — старения и смерти, 
    и объявить эту задачу первостепенной`,
    "home.manifest.text.4": `<span>признавая</span>, что старение является заболеванием, которое несет огромную опасность для жизни человека,`,
    "home.manifest.text.5": `<span>сознавая</span>, что необходимы коренные изменения в политике всех 
    государств относительно мер по профилактике и лечению старения и возрастных заболеваний,`,
    "home.manifest.text.6": `<span>сознавая также</span>, что необходимы существенные изменения в сознании человека касательно 
    старения и смерти как неизбежных и обязательных явлений в жизни человека,`,
    "home.manifest.text.7": `<span>граждане Vitaspace</span> и <span>участники проекта Vitacoin провозглашают</span> настоящую 
    <span>Декларацию по проблемам радикального продления жизни</span> в качестве задачи, 
    к выполнению которой должны стремиться все люди и правительства всех стран.`,

    
    "description.tab.coatOfArms": "Герб",
    "description.tab.flag": "Флаг",

    "description.tab.coatOfArms.content": `Герб Vitaspace представляет собой литеру «V», отсылающую к латинскому слову vita, что значит «жизнь», и 
    знак бесконечности. Вместе они образуют символ вечной жизни.`,

    "description.tab.flag.content": `Представляет собой прямоугольное полотнище белого цвета —
      символ благородства, открытости и знаний. В центре флага
      расположен герб децентрализованного экстерриториального
      государства Vitaspace.`,

    "description.tab.vitapass.content.1": `Vitapass — это основной электронный документ установленного
      образца, удостоверяющий личность гражданина Vitaspace. Для
      получения Vitapass нужно пройти простую регистрацию. В паспорт
      вносятся следующие сведения:`,
    "description.tab.vitapass.content.2": `Электронный паспорт можно приобрести и в одном из двух
      аналоговых видов: золотой или платиновый, которые представляют
      собой пластиковую карту установленного образца.`,
    "description.tab.vitapass.content.li.names":
      "фамилия, имя, отчество гражданина",
    "description.tab.vitapass.content.li.date": "дата рождения",
    "description.tab.vitapass.content.li.id": "идентификационный номер.",

    "google.recaptcha.terms":
      "Данный сайт защищен reCaptcha, к которой применяются",
    "google.recaptcha.terms.and": "и",
    "google.recaptcha.terms.policy": "Политика конфиденциальности",
    "google.recaptcha.terms.terms": "Условия обслуживания",
    "google.recaptcha.terms.google": "Google.",

    "settings.back.button": "Назад",
    "settings.settings": "Настройки",
    "settings.change_password": "Смена пароля",
    "setting.password_doest_match": "Пароли не совпадают",
    "settings.old_password": "Текущий пароль",
    "settings.new_password": "Новый пароль",
    "settings.re_new_password": "Повторите новый пароль",
    "settings.save.button": "Сохранить",

    "graph.usercount": "Количество граждан"
  }
};
