import React, { PureComponent as Component, Fragment } from "react";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";

import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";
import {orderActions, userActions} from "../../actions";
import VitaPassCard from "./VitaPassCard";
import Tilt from "react-parallax-tilt";

import logout_icon from "./logout_icon.svg";
import close_icon from "./close.svg";
import settings_icon from "./settings.svg";

import {
  ModalWrapper,
  ModalContent,
  OrderWrapper,
  LogoutWrapper,
  SettingsWrapper,
  LkFooter,
  OrderButton,
  Grid,
  Logout,
  DescriptionP,
  SystemMessage
} from "./styled";
import Loading from "../Loading";

const TiltWrapper = styled.div`
  margin: 0 auto;
  width: 272px;
  height: 170px;
  touch-action: none;

  @media screen and (min-width: 576px) {
    width: 480px;
    height: 300px;
  }
`;

class AccountBlock extends Component {
  componentDidMount() {
    const { user, getById } = this.props;
    getById(user.id)
  }

  render() {
    const { user_data, t } = this.props;
    const { data } = user_data;
    const { checkAddress } = this.props

    return (
      <ModalWrapper>
        <ModalContent>
          <Grid>
            <LogoutWrapper>
              {!user_data.error && data && (
                <Logout to="/">
                  {/* <span>{t("account.close")}</span> */}
                  <img src={close_icon} alt="" />
                </Logout>
              )}
            </LogoutWrapper>
            <Row middle="md">
              <Col md={12}>
                <h2>
                  {!user_data.error &&
                    data && (
                      <span>
                        {t("account.welcome")}, {data.first_name}!
                      </span>
                    )}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {user_data.loading && (
                  <SystemMessage>
                    <Loading light big />
                  </SystemMessage>
                )}
                {user_data.error && (
                  <Fragment>
                    <SystemMessage>{t("error")}.</SystemMessage>
                    <SystemMessage>
                      <Logout to="/login">
                        <span>{t("auth.sign.out")}</span>
                        <img src={logout_icon} alt="" />
                      </Logout>
                    </SystemMessage>
                  </Fragment>
                )}
                {data && (
                  <OrderWrapper>
                    <TiltWrapper>
                      <Tilt
                        // tiltReverse={true}
                        glareEnable={true}
                        glareMaxOpacity={0.2}
                        glarePosition="all"
                        perspective={1000}
                        transitionSpeed={1400}
                        scale={1.02}
                        tiltMaxAngleX={15}
                        tiltMaxAngleY={15}
                      >
                        <VitaPassCard
                          active={false}
                          card={{
                            name: data.profile.first_name_card,
                            lastname: data.profile.last_name_card,
                            photo: data.avatar,
                            number: data.profile.card_number,
                            valid: "XX/XX",
                            identifier: data.username
                          }}
                        />
                      </Tilt>
                    </TiltWrapper>
                    {/* <OrderButton
                      href="//vitacoin.net/ru/profile/pay/"
                      target="_blank"
                    > */}
                    <OrderButton style={{ filter: "grayscale(1)" }} href={data.robokassa_url}>
                      {t("orderVitapass")} (2 vitacoins)
                    </OrderButton>
                    <OrderButton onClick={() => checkAddress()}>Проверить адресс</OrderButton>
                    {/*<p*/}
                    {/*  style={{*/}
                    {/*    background: "#fbd7d7",*/}
                    {/*    padding: "16px",*/}
                    {/*    borderRadius: "8px",*/}
                    {/*    color: "#845f5f"*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <a href=>Приобрести</a>*/}
                    {/*</p>*/}
                    <DescriptionP>
                      {t("account.changeCaption")}{" "}
                      <a href="mailto:support@vitaspace.net">
                        support@vitaspace.net
                      </a>
                    </DescriptionP>
                  </OrderWrapper>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <LkFooter>
                  <SettingsWrapper>
                    {!user_data.error && data && (
                      <Logout to="/settings">
                        <span>{t("settings.settings")}</span>
                        <img src={settings_icon} alt="" />
                      </Logout>
                    )}
                  </SettingsWrapper>
                  <LogoutWrapper>
                    {!user_data.error && data && (
                      <Logout to="/login">
                        <span>{t("auth.sign.out")}</span>
                        <img src={logout_icon} alt="" />
                      </Logout>
                    )}
                  </LogoutWrapper>
                </LkFooter>
              </Col>
            </Row>
          </Grid>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { registration, authentication, user_data } = state;
  const { user } = registration.user ? registration : authentication;
  return {
    user,
    user_data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkAddress: () => dispatch(orderActions.checkAddress()),
    getById: (id) => dispatch(userActions.getById(id)),
  };
}

const connectedAccountBlock = connect(
    mapStateToProps,
    mapDispatchToProps
    )(AccountBlock);
export default withTranslation()(connectedAccountBlock);
