import React, {Fragment, PureComponent as Component} from "react";
import {


    ModalInput, ModalInputIcon, ModalInputWrapper,

     StyledForm
} from "../Register/styled";
import address_icon from "./address_icon.svg";
import phone_icon from "../Register/telephone.svg";
import user_icon from "../Register/user.svg";
import {Form, Formik} from "formik";
import { withTranslation} from "react-i18next";
import {languages} from "../../languages/languages";
import {connect} from "react-redux";
import {orderActions, userActions} from "../../actions";
import {Button, ErrorsList, Link, ModalContent, ModalHeader, ModalWrapper, PriceWrapper} from "./styled";
import VitaPassCard from "./VitaPassCard";
import {Spinner} from "../Spinner";


class OrderForm extends Component {

    componentDidMount() {
        const { user, getById } = this.props;
        getById(user.id)
    }

    getCurrentLang = () => {
        const i18nextLng = localStorage.getItem("i18nextLng");
        return languages.find(lang => i18nextLng === lang.code);
    };
    render() {

        const { t } = this.props;
        const { user, user_data, checkAddress } = this.props;
        const current_lang = this.getCurrentLang();
        const { data } = user_data;
        const { address, calculatingAddress } = this.props
        return (
            <ModalWrapper>
                <ModalHeader>
                    <h2>Заказать физический VitaPass</h2>
                    {data && (
                        <VitaPassCard
                            active={false}
                            card={{
                                name: data.profile.first_name_card,
                                lastname: data.profile.last_name_card,
                                photo: data.avatar,
                                number: data.profile.card_number,
                                valid: "XX/XX",
                                identifier: data.username
                            }}
                        />
                    )}

                </ModalHeader>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        address: "",
                        name: "",
                        lastname: "",
                        phone: ""

                    }}
                    validate={values => {
                        const errors = {};
                        if (!values.name) {
                            errors.name = "Имя бязательное поле!"
                        }
                        if (!values.lastname) {
                            errors.lastname = "Фамилия бязательное поле!"
                        }
                        if (!values.lastname) {
                            errors.phone = "Телефон бязательное поле!"
                        }
                        if (!values.address) {
                            errors.address = "Адрес бязательное поле!"
                        }
                        return errors
                    }}
                    onSubmit={(values, { setSubmitting }) => {

                        console.log(values);
                        checkAddress(values);
                        setSubmitting(false);
                    }}
                >
                    {props => {
                        return (
                            <Form>
                                <ModalContent>

                                    {address.errors  && (
                                        <ErrorsList>
                                                <ul>
                                                   <li>{t(address.errors)}</li>
                                                </ul>
                                        </ErrorsList>
                                    )}
                                    <p>
                                        <span>Внимание!</span> На карте будут напечатаны фамилия и имя, указанные Вами при регистрации.
                                        Изменить данные Вы можете в настройках личного кабинета.
                                    </p>
                                    <ModalInputWrapper>
                                        <ModalInputIcon src={address_icon} alt="" />
                                        <ModalInput
                                            name="address"
                                            value={props.values.address}
                                            onChange={props.handleChange}
                                            type="text"
                                            placeholder="Адрес"
                                        />
                                    </ModalInputWrapper>
                                    {props.errors.address ?
                                        <ErrorsList>
                                            <ul >
                                                <li>{props.errors.address}</li>
                                            </ul>
                                        </ErrorsList> : null}
                                    <ModalInputWrapper>
                                        <ModalInputIcon src={user_icon} alt="" />
                                        <ModalInput
                                            name="name"
                                            value={props.values.name}
                                            onChange={props.handleChange}
                                            type="text"
                                            placeholder={t("auth.name")}
                                        />
                                    </ModalInputWrapper>
                                    {props.errors.name ?
                                        <ErrorsList>
                                            <ul >
                                                <li>{props.errors.name}</li>
                                            </ul>
                                        </ErrorsList> : null}
                                    <ModalInputWrapper>
                                        <ModalInputIcon src={user_icon} alt="" />
                                        <ModalInput
                                            name="lastname"
                                            value={props.values.lastname}
                                            onChange={props.handleChange}
                                            type="text"
                                            placeholder={t("auth.surname")}
                                        />
                                    </ModalInputWrapper>
                                    {props.errors.lastname ?
                                        <ErrorsList>
                                            <ul >
                                                <li>{props.errors.lastname}</li>
                                            </ul>
                                        </ErrorsList> : null}
                                    <ModalInputWrapper>
                                        <ModalInputIcon src={phone_icon} alt="" />
                                        <ModalInput
                                            name="phone"
                                            value={props.values.phone}
                                            onChange={props.handleChange}
                                            type="text"
                                            placeholder={t("auth.phone")}
                                        />
                                    </ModalInputWrapper>
                                    {props.errors.phone ?
                                        <ErrorsList>
                                            <ul >
                                                <li>{props.errors.phone}</li>
                                            </ul>
                                        </ErrorsList> : null}
                                    {address.data && address.data.total_price ? (
                                        <PriceWrapper>
                                            <span>
                                                К оплате {parseFloat(address.data.total_price).toFixed(2)} р
                                            </span>
                                        </PriceWrapper>
                                    ) : (
                                        <></>
                                    )}


                                </ModalContent>
                                {address.data  ? (
                                    <Link href={address.data.robocassa_url}>
                                        Перейти к оплате
                                    </Link>
                                ) : (
                                    <Button type="submit">

                                        {calculatingAddress ? <Spinner /> : "Рассчитать сотимость" }
                                    </Button>
                                )}

                            </Form>
                    )}}</Formik>
            </ModalWrapper>
        );
    }
}

function mapStateToProps(state) {
    const { registration, authentication, user_data } = state;
    const { address, calculatingAddress } = state.order
    const { user } = registration.user ? registration : authentication;
    return {
        user,
        user_data,
        address,
        calculatingAddress
    };
}

function mapDispatchToProps(dispatch) {
    return {
        checkAddress: (data) => dispatch(orderActions.checkAddress(data)),
        getById: (id) => dispatch(userActions.getById(id)),
    };
}

const connectedOrderPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderForm);

export default withTranslation()(connectedOrderPage);