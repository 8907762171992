import en from "./en";
import ru from "./ru";
import es from "./es";
import cn from "./cn";

export default {
  en,
  ru,
  es,
  cn
};
