export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETBYID_REQUEST: "USERS_GETBYID_REQUEST",
  GETBYID_SUCCESS: "USERS_GETBYID_SUCCESS",
  GETBYID_FAILURE: "USERS_GETBYID_FAILURE",

  CHECK_PASSWORD_REQUEST: "USERS_CHANGE_PASSWORD_REQUEST",
  CHECK_PASSWORD_SUCCESS: "USERS_CHANGE_PASSWORD_SUCCESS",
  CHECK_PASSWORD_FAILURE: "USERS_CHANGE_PASSWORD_FAILURE",

  CHANGE_PASSWORD_REQUEST: "USERS_CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "USERS_CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "USERS_CHANGE_PASSWORD_FAILURE",

  FORGOT_PASSWORD_REQUEST: "USERS_FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "USERS_FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "USERS_FORGOT_PASSWORD_FAILURE",

  PHOTO_UPDATE_REQUEST: "PHOTO_UPDATE_REQUEST",
  PHOTO_UPDATE_SUCCESS: "PHOTO_UPDATE_SUCCESS",
  PHOTO_UPDATE_FAILURE: "PHOTO_UPDATE_FAILURE",

  PHOTO_CONSENT_REQUEST: "PHOTO_CONSENT_REQUEST",
  PHOTO_CONSENT_SUCCESS: "PHOTO_CONSENT_SUCCESS",
  PHOTO_CONSENT_FAILURE: "PHOTO_CONSENT_FAILURE",

  CLEAN_STATE: "USERS_CHANGE_PASSWORD_CLEAN_STATE"
};
