import {authHeader} from "../helpers";
import {fetch} from "whatwg-fetch";
import {handleResponse, config} from "./user.service";

export const orderService = {
    checkAddress
};


function checkAddress(data) {
    const form_data = new FormData();
    form_data.append("address", data.address);
    form_data.append("phone", data.phone);
    form_data.append("name", data.name);
    form_data.append("lastname", data.lastname);

    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: form_data
    };

    return fetch(`${config.apiUrl}/v1/order/calculate_price/`, requestOptions).then(
        handleResponse
    ).then(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(data)
        // window.location.href = data.data.robocassa_url
        return data;
    });
}